<!-- Landing.svelte -->
<script lang="ts">
    import { navigate } from "svelte-routing";
    import Login from "./Login.svelte";
    //import Cookie from "./Cookie.svelte";
    import "./app.css";
    import { onMount } from "svelte";
    import Achievement from "./Achievements.svelte";
    import PlezuzPuzzle from "./PlezuzPuzzle.svelte";

    onMount(() => {});

    // let isMenuOpen = false;

    // const toggleMenu = () => {
    //     // event.preventDefault();
    //     isMenuOpen = !isMenuOpen;
    // };

    // Close the menu when clicking outside of it
    // onMount(() => {
    //     const closeMenuOnClickOutside = (event) => {
    //         if (isMenuOpen && !event.target.closest(".menu-button")) {
    //             toggleMenu();
    //         }
    //     };

    //     document.addEventListener("click", closeMenuOnClickOutside);

    //     return () => {
    //         document.removeEventListener("click", closeMenuOnClickOutside);
    //     };
    // });
</script>

<!-- <div class="container-flex">
<Menu hamburger={true}></Menu>
<Profile></Profile>
</div> -->

<!-- <Cookie></Cookie> -->

<div class="login-signup-button">
    <PlezuzPuzzle></PlezuzPuzzle>
    <button on:click={() => navigate("/login")} class="button cstm" data-test="login-button" type="button" style="color: black;"
        >LOG IN</button
    >

    <button on:click={() => navigate("/signup")} class="button cstm" data-test="signup-button" type="button" style="color: black;"
        >SIGN UP</button
    >
</div>
