<script lang="ts">
    import "./Home.css";
    import Menu from "./Menu.svelte";
    import Profile from "./Profile.svelte";
    import { Link } from "svelte-routing";
    import { fetchGet } from "./services/fetch";
    import { MentalMathTaskSolveVm } from "./crambase";
    import IntroMathLink from "./IntroMathLink.svelte";
    import MentalMathLink from "./MentalMathLink.svelte";
    import MemoryMathLink from "./MemoryMathLink.svelte";
    import AppliedMathLink from "./AppliedMathLink.svelte";
    import Podium from "./Podium.svelte";
    import IntroEnd from "./gamestart/Intro_end.svelte";
    import AppliedIntro from "./gamestart/Applied_start.svelte";
    import MemoryIntro from "./gamestart/Memory_start.svelte";
    import MentalIntro from "./gamestart/Mental_start.svelte";
    import { rating } from "./services/stores";
    import { getProgressVm, getHomeTasksVm, type TaskVm } from "./services/Contact";

    let introProgress = 0;
    let memoryProgress = 0;
    let mentalMathProgress = 0;
    let appliedMathProgress = 0;

    let showPathIntro = false;
    let showAppliedIntro = false;
    let showMemoryIntro = false;
    let showMentalIntro = false;

    $: console.log("memoryProgress updated:", memoryProgress);
    $: console.log("mentalMathProgress updated:", mentalMathProgress);
    $: console.log("appliedMathProgress updated:", appliedMathProgress);

    $: getProgress();

    let dummyState = 0;

    async function getProgress() {
        //let progress = await fetchGet<ProgressVm>("Progress/GetProgress");
        let progress = await getProgressVm();

        //console.log("Before update", { memoryProgress });
        memoryProgress = progress.memoryProgress;
        //console.log("After update", { memoryProgress });

        introProgress = progress.introProgress;
        mentalMathProgress = progress.mentalMathProgress;
        appliedMathProgress = progress.appliedMathProgress;

        //console.log("fetch memoryPogress: " + memoryProgress);
        dummyState++; // trigger Svelte to re-check all dependencies

        //console.log("dummyState " + dummyState);
    }

    async function continueMathIntro() {
        await fetchGet<void>("FastMentalmath/ContinueMathIntro");
        await getProgress();
        showPathIntro = false;
    }

    async function continueMemoryIntro() {
        await fetchGet<void>("Crammer/ContinueMemoryIntro");
        await getProgress();
        showMemoryIntro = false;
    }

    async function continueAppliedIntro() {
        await fetchGet<void>("FastMentalmath/ContinueAppliedIntro");
        await getProgress();
        showAppliedIntro = false;
    }

    async function continueMentalMathIntro() {
        await fetchGet<void>("FastMentalmath/ContinueMentalMathIntro");
        await getProgress();
        showMentalIntro = false;
    }

    function closeOpen() {
        showPathIntro = false;
        showMentalIntro = false;
        showAppliedIntro = false;
        showMemoryIntro = false;
    }

    function onIntroClick() {
        let isOpen = showPathIntro;
        closeOpen();
        showPathIntro = !isOpen;
    }
    function onMentalIntroClick() {
        let isOpen = showMentalIntro;
        closeOpen();
        showMentalIntro = !isOpen;
    }

    function onAppliedIntroClick() {
        let isOpen = showAppliedIntro;
        closeOpen();
        showAppliedIntro = !isOpen;
    }

    function onMemoryIntroClick() {
        let isOpen = showMemoryIntro;
        closeOpen();
        showMemoryIntro = !isOpen;
    }

    let isActive = false;
    function isRedActive(value: number): boolean {
        return isActive && value === 2;
    }

    function isYellowActive(value: number): boolean {
        console.log("isYellowActive memoryProgress" + memoryProgress + " value" + value);
        return memoryProgress >= value;
    }

    interface TaskInterfaceVm {
        Url: string;
        Title: string;
        Value: number;
    }

    
    let taskTable: TaskInterfaceVm[] = [];
    let memoryTaskTable: TaskInterfaceVm[] = [];
    let introTaskTable: TaskInterfaceVm[] = [];
    let appliedTaskTable: TaskInterfaceVm[] = [];
    
    $: getHomeTasks(); //TODO: Check if the $: is necessary

    async function getHomeTasks() {
        const tasks: TaskVm = await getHomeTasksVm();

        taskTable = tasks.MentalTasks;
        memoryTaskTable = tasks.MemoryTasks;
        introTaskTable = tasks.IntroTasks;
        appliedTaskTable = tasks.AppliedTasks;

        rating.set(tasks.rating);
    }


    function cell(cellNumber: number) {
        //console.log(cellNumber);
        const row = Math.floor(cellNumber / 10);
        const column = cellNumber % 10 === 0 ? 10 : cellNumber % 10;

        let style = `grid-row: ${row}; grid-column: ${column};`;
        //      console.log(style);
        return style;
    }

    function cells(className: string): string {
        let style = "";
        // Regex to match the pattern: row_start[-row_end]_col_start[-col_end]
        const match = className.match(/(\d+)(?:-(\d+))?_(\d+)(?:-(\d+))?/);

        if (match) {
            const startRow = match[1];
            const endRow = match[2] || startRow;
            const startCol = match[3];
            const endCol = match[4] || startCol;

            style = `grid-row: ${startRow}/${endRow}; grid-column: ${startCol}/${endCol};`;
        } else {
            console.log(`No match for className: ${className}`);
        }

        //console.log(`className: ${className} style: ${style}`);

        return style;
    }

    //Compiler complained about on:click on div
    function handleKeydown(event: any, action: any) {
        if (event.key === "Enter" || event.key === " ") {
            action();
        }
    }
</script>

{#if showPathIntro}
    <IntroEnd onContinue={continueMathIntro}></IntroEnd>
{/if}

{#if showAppliedIntro}
    <AppliedIntro onContinue={continueAppliedIntro}></AppliedIntro>
{/if}
{#if showMemoryIntro}
    <MemoryIntro onContinue={continueMemoryIntro}></MemoryIntro>
{/if}

{#if showMentalIntro}
    <MentalIntro onContinue={continueMentalMathIntro}></MentalIntro>
{/if}
<div class="progress-chart">
    <div class="grid-container">
        <div class="whitebox" style={cell(11)}>
            <!-- <div class="square-button button">
                <div class="task">
                    <div class="info-window">
                        <div class="content">
                            <div class="child-text">
                                <span class="heading">WELCOME TO PLEZUZ!</span>
                                <span class="text-b">Hi! im hover qpsojgnur9erqpuh sidjnskbjm zffgerfcvgysd</span>
                                <button class="continue-btn"> Continue</button>
                            </div>
                            <Podium></Podium>
                        </div>
                    </div>
                </div>
            </div>
            <p>INTRODUCTION</p> -->
        </div>
        <div class="graybox" style={cell(12)}>
            <!-- <IntroMathLink taskNumber={0} progress={introProgress} {introTaskTable}></IntroMathLink> -->
        </div>
        <div class="whitebox" style={cell(13)}>
            <!-- <div class="big-circle1 button" class:red={isRedActive(3)} class:gray={!isRedActive(3)}></div> -->
            <!-- <IntroMathLink taskNumber={1} progress={introProgress} {introTaskTable}></IntroMathLink> -->
        </div>
        <div class="graybox" style={cell(14)}></div>
        <div class="whitebox" style={cell(15)}></div>
        <div class="graybox" style={cell(16)}></div>
        <div class="whitebox" style={cell(17)}></div>
        <div class="graybox" style={cell(21)}>
            <MemoryMathLink taskNumber={1} progress={memoryProgress} {memoryTaskTable}></MemoryMathLink>
        </div>

        {#if memoryProgress >= 0}
            <div on:click={onMemoryIntroClick} role="button" tabindex="-1" class="whitebox" style={cell(22)}>
                <div class="memory-button button yellow"></div>
                <p class="">MEMORY LESSON</p>
            </div>
        {:else}
            <div class="whitebox" style={cell(22)}>
                <div class="memory-button button gray"></div>
                <p>MEMORY LESSON</p>
            </div>
        {/if}

        <div class="graybox" style={cell(23)}>
            {#if introProgress > 0}
                <div
                    on:click={onIntroClick}
                    role="button"
                    tabindex="-2"
                    class="intro-button button red"
                    class:red={isRedActive(4)}
                    class:gray={!isRedActive(4)}
                ></div>
            {:else}
                <div class="intro-button button gray" class:red={isRedActive(4)} class:gray={!isRedActive(4)}></div>
            {/if}
        </div>

        <div class="whitebox" style={cell(24)}>
            {#if mentalMathProgress >= 0}
                <div on:click={onMentalIntroClick} role="button" tabindex="-3" class="mental-button button blue">
                    <div class="task">
                        <div class="info-window">
                            <div class="content">
                                <div class="child-text">
                                    <span class="heading">WELCOME TO PLEZUZ!</span>
                                    <span class="text-b">Hi! im hover qpsojgnur9erqpuh sidjnskbjm zffgerfcvgysd</span>
                                    <button class="continue-btn"> Continue</button>
                                </div>
                                <Podium></Podium>
                            </div>
                        </div>
                    </div>
                </div>
                <p>MENTAL MATH</p>
            {:else}
                <div class="mental-button button gray">
                    <div class="task">
                        <div class="info-window">
                            <div class="content">
                                <div class="child-text">
                                    <span class="heading">WELCOME TO PLEZUZ!</span>
                                    <span class="text-b">Hi! im hover qpsojgnur9erqpuh sidjnskbjm zffgerfcvgysd</span>
                                    <button class="continue-btn"> Continue</button>
                                </div>
                                <Podium></Podium>
                            </div>
                        </div>
                    </div>
                </div>
                <p>MENTAL MATH</p>
            {/if}
        </div>

        <div class="graybox" style={cell(25)}>
            <MentalMathLink taskNumber={1} progress={mentalMathProgress} {taskTable}></MentalMathLink>
        </div>
        <div class="whitebox" style={cell(26)}>
            <MentalMathLink taskNumber={2} progress={mentalMathProgress} {taskTable}></MentalMathLink>
        </div>
        <div class="graybox" style={cell(27)}>
            <MentalMathLink taskNumber={3} progress={mentalMathProgress} {taskTable}></MentalMathLink>
        </div>

        <div class="whitebox" style={cell(31)}>
            <MemoryMathLink taskNumber={2} progress={memoryProgress} {memoryTaskTable}></MemoryMathLink>
        </div>
        <div class="graybox" style={cell(32)}>
            <MemoryMathLink taskNumber={3} progress={memoryProgress} {memoryTaskTable}></MemoryMathLink>
        </div>

        {#if appliedMathProgress >= 0}
            <div on:click={onAppliedIntroClick} role="button" tabindex="-3" class="whitebox" style={cell(33)}>
                <div class="applied-button button green"></div>
                <p>APPLIED MATH</p>
            </div>
        {:else}
            <div class="whitebox" style={cell(33)}>
                <div class="applied-button button gray"></div>
                <p>APPLIED MATH</p>
            </div>
        {/if}

        <div class="graybox" style={cell(34)}>
            <AppliedMathLink taskNumber={1} progress={appliedMathProgress} {appliedTaskTable}></AppliedMathLink>
        </div>
        <div class="whitebox" style={cell(35)}>
            <MentalMathLink taskNumber={6} progress={mentalMathProgress} {taskTable}></MentalMathLink>
        </div>
        <div class="graybox" style={cell(36)}>
            <MentalMathLink taskNumber={5} progress={mentalMathProgress} {taskTable}></MentalMathLink>
        </div>
        <div class="whitebox" style={cell(37)}>
            <MentalMathLink taskNumber={4} progress={mentalMathProgress} {taskTable}></MentalMathLink>
        </div>
        <div class="graybox" style={cell(41)}>
            <MemoryMathLink taskNumber={5} progress={memoryProgress} {memoryTaskTable}></MemoryMathLink>
        </div>
        <div class="whitebox" style={cell(42)}>
            <MemoryMathLink taskNumber={4} progress={memoryProgress} {memoryTaskTable}></MemoryMathLink>
        </div>
        <div class="graybox" style={cell(43)}>
            <AppliedMathLink taskNumber={3} progress={appliedMathProgress} {appliedTaskTable}></AppliedMathLink>
        </div>
        <!-- <div class="whitebox" style={cell(44)}>
            <div class="small-circle button"></div>
        </div> -->
        <div class="whitebox" style={cell(44)}>
            <AppliedMathLink taskNumber={2} progress={appliedMathProgress} {appliedTaskTable}></AppliedMathLink>
        </div>

        <div class="graybox" style={cell(45)}>
            <MentalMathLink taskNumber={7} progress={mentalMathProgress} {taskTable}></MentalMathLink>
        </div>
        <div class="whitebox" style={cell(46)}>
            <MentalMathLink taskNumber={8} progress={mentalMathProgress} {taskTable}></MentalMathLink>
        </div>
        <div class="graybox" style={cell(47)}>
            <MentalMathLink taskNumber={9} progress={mentalMathProgress} {taskTable}></MentalMathLink>
        </div>
        <div class="whitebox" style={cell(51)}>
            <MemoryMathLink taskNumber={6} progress={memoryProgress} {memoryTaskTable}></MemoryMathLink>
        </div>
        <div class="graybox" style={cell(52)}>
            <MemoryMathLink taskNumber={7} progress={memoryProgress} {memoryTaskTable}></MemoryMathLink>
        </div>
        <div class="whitebox" style={cell(53)}>
            <AppliedMathLink taskNumber={4} progress={appliedMathProgress} {appliedTaskTable}></AppliedMathLink>
        </div>
        <div class="graybox" style={cell(54)}>
            <AppliedMathLink taskNumber={5} progress={appliedMathProgress} {appliedTaskTable}></AppliedMathLink>
        </div>
        <div class="whitebox" style={cell(55)}>
            <MentalMathLink taskNumber={12} progress={mentalMathProgress} {taskTable}></MentalMathLink>
        </div>
        <div class="graybox" style={cell(56)}>
            <MentalMathLink taskNumber={11} progress={mentalMathProgress} {taskTable}></MentalMathLink>
        </div>
        <div class="whitebox" style={cell(57)}>
            <MentalMathLink taskNumber={10} progress={mentalMathProgress} {taskTable}></MentalMathLink>
        </div>
        <div class="graybox" style={cell(61)}>
            <MemoryMathLink taskNumber={9} progress={memoryProgress} {memoryTaskTable}></MemoryMathLink>
        </div>
        <div class="whitebox" style={cell(62)}>
            <MemoryMathLink taskNumber={8} progress={memoryProgress} {memoryTaskTable}></MemoryMathLink>
        </div>
        <div class="graybox" style={cell(63)}>
            <AppliedMathLink taskNumber={7} progress={appliedMathProgress} {appliedTaskTable}></AppliedMathLink>
        </div>
        <div class="whitebox" style={cell(64)}>
            <AppliedMathLink taskNumber={6} progress={appliedMathProgress} {appliedTaskTable}></AppliedMathLink>
        </div>
        <div class="graybox" style={cell(65)}>
            <MentalMathLink taskNumber={13} progress={mentalMathProgress} {taskTable}></MentalMathLink>
        </div>
        <div class="whitebox" style={cell(66)}>
            <MentalMathLink taskNumber={14} progress={mentalMathProgress} {taskTable}></MentalMathLink>
        </div>
        <div class="graybox" style={cell(67)}>
            <MentalMathLink taskNumber={15} progress={mentalMathProgress} {taskTable}></MentalMathLink>
        </div>
        <div class="whitebox" style={cell(71)}>
            <MemoryMathLink taskNumber={10} progress={memoryProgress} {memoryTaskTable}></MemoryMathLink>
        </div>
        <div class="graybox" style={cell(72)}>
            <MemoryMathLink taskNumber={11} progress={memoryProgress} {memoryTaskTable}></MemoryMathLink>
        </div>
        <div class="whitebox" style={cell(73)}>
            <AppliedMathLink taskNumber={8} progress={appliedMathProgress} {appliedTaskTable}></AppliedMathLink>
        </div>
        <div class="graybox" style={cell(74)}>
            <AppliedMathLink taskNumber={9} progress={appliedMathProgress} {appliedTaskTable}></AppliedMathLink>
        </div>
        <div class="whitebox" style={cell(75)}>
            <MentalMathLink taskNumber={18} progress={mentalMathProgress} {taskTable}></MentalMathLink>
        </div>
        <div class="graybox" style={cell(76)}>
            <MentalMathLink taskNumber={17} progress={mentalMathProgress} {taskTable}></MentalMathLink>
        </div>
        <div class="whitebox" style={cell(7)}>
            <MentalMathLink taskNumber={16} progress={mentalMathProgress} {taskTable}></MentalMathLink>
        </div>
        <div class="graybox" style={cell(81)}>
            <MemoryMathLink taskNumber={13} progress={memoryProgress} {memoryTaskTable}></MemoryMathLink>
        </div>
        <div class="whitebox" style={cell(82)}>
            <MemoryMathLink taskNumber={12} progress={memoryProgress} {memoryTaskTable}></MemoryMathLink>
        </div>
        <div class="graybox" style={cell(83)}>
            <AppliedMathLink taskNumber={11} progress={appliedMathProgress} {appliedTaskTable}></AppliedMathLink>
        </div>
        <div class="whitebox" style={cell(84)}>
            <AppliedMathLink taskNumber={10} progress={appliedMathProgress} {appliedTaskTable}></AppliedMathLink>
        </div>
        <div class="graybox" style={cell(85)}>
            <MentalMathLink taskNumber={19} progress={mentalMathProgress} {taskTable}></MentalMathLink>
        </div>
        <div class="whitebox" style={cell(86)}>
            <MentalMathLink taskNumber={20} progress={mentalMathProgress} {taskTable}></MentalMathLink>
        </div>
        <div class="graybox" style={cell(87)}>
            <MentalMathLink taskNumber={21} progress={mentalMathProgress} {taskTable}></MentalMathLink>
        </div>
        <div class="whitebox" style={cell(91)}></div>
        <div class="graybox" style={cell(92)}></div>
        <div class="whitebox" style={cell(93)}>
            <AppliedMathLink taskNumber={12} progress={appliedMathProgress} {appliedTaskTable}></AppliedMathLink>
        </div>
        <div class="graybox" style={cell(94)}>
            <AppliedMathLink taskNumber={13} progress={appliedMathProgress} {appliedTaskTable}></AppliedMathLink>
        </div>
        <div class="whitebox" style={cell(95)}>
            <MentalMathLink taskNumber={24} progress={mentalMathProgress} {taskTable}></MentalMathLink>
        </div>
        <div class="graybox" style={cell(96)}>
            <MentalMathLink taskNumber={23} progress={mentalMathProgress} {taskTable}></MentalMathLink>
        </div>
        <div class="whitebox" style={cell(97)}>
            <MentalMathLink taskNumber={22} progress={mentalMathProgress} {taskTable}></MentalMathLink>
        </div>
        <div class="graybox" style={cell(101)}></div>
        <div class="whitebox" style={cell(102)}></div>
        <div class="graybox" style={cell(103)}>
            <AppliedMathLink taskNumber={15} progress={appliedMathProgress} {appliedTaskTable}></AppliedMathLink>
        </div>
        <div class="whitebox" style={cell(104)}>
            <AppliedMathLink taskNumber={14} progress={appliedMathProgress} {appliedTaskTable}></AppliedMathLink>
        </div>
        <div class="graybox" style={cell(105)}>
            <MentalMathLink taskNumber={25} progress={mentalMathProgress} {taskTable}></MentalMathLink>
        </div>
        <div class="whitebox" style={cell(106)}>
            <MentalMathLink taskNumber={26} progress={mentalMathProgress} {taskTable}></MentalMathLink>
        </div>
        <div class="graybox" style={cell(107)}>
            <MentalMathLink taskNumber={27} progress={mentalMathProgress} {taskTable}></MentalMathLink>
        </div>
        <div class="whitebox" style={cell(111)}></div>
        <div class="graybox" style={cell(112)}></div>
        <div class="whitebox" style={cell(113)}>
            <MentalMathLink taskNumber={32} progress={mentalMathProgress} {taskTable}></MentalMathLink>
        </div>
        <div class="graybox" style={cell(114)}>
            <MentalMathLink taskNumber={31} progress={mentalMathProgress} {taskTable}></MentalMathLink>
        </div>
        <div class="whitebox" style={cell(115)}>
            <MentalMathLink taskNumber={30} progress={mentalMathProgress} {taskTable}></MentalMathLink>
        </div>
        <div class="graybox" style={cell(116)}>
            <MentalMathLink taskNumber={29} progress={mentalMathProgress} {taskTable}></MentalMathLink>
        </div>
        <div class="whitebox" style={cell(117)}>
            <MentalMathLink taskNumber={28} progress={mentalMathProgress} {taskTable}></MentalMathLink>
        </div>
        <!-- TODO IntroLink -->
        <!-- These are the lines between the cells -->
        <!-- <div style={cells("1_1-2")} class="horizontal-line lines {introProgress >= 1 ? 'intro-red' : 'gray'}"></div>

        <div style={cells("1_2-3")} class="horizontal-line lines {introProgress >= 2 ? 'intro-red' : 'gray'}"></div>

        <div style={cells("1-2_3")} class="vertical-line lines {introProgress >= 3 ? 'intro-red' : 'gray'}"></div> -->

        <div style={cells("2_1-2")} class="horizontal-line lines {memoryProgress >= 1 ? 'memory-yellow' : 'gray'}"></div>
        <div style={cells("2-3_1")} class="vertical-line lines {memoryProgress >= 2 ? 'memory-yellow' : 'gray'}"></div>
        <div style={cells("3_1-2")} class="horizontal-line lines {memoryProgress >= 3 ? 'memory-yellow' : 'gray'}"></div>
        <div style={cells("4_1-2")} class="horizontal-line lines {memoryProgress >= 5 ? 'memory-yellow' : 'gray'}"></div>
        <div style={cells("3-4_2")} class="vertical-line lines {memoryProgress >= 4 ? 'memory-yellow' : 'gray'}"></div>
        <div style={cells("4-5_1")} class="vertical-line lines {memoryProgress >= 6 ? 'memory-yellow' : 'gray'}"></div>
        <div style={cells("5_1-2")} class="horizontal-line lines {memoryProgress >= 7 ? 'memory-yellow' : 'gray'}"></div>
        <div style={cells("6_1-2")} class="horizontal-line lines {memoryProgress >= 9 ? 'memory-yellow' : 'gray'}"></div>
        <div style={cells("2_2-3")} class="horizontal-dotted-line lines"></div>
        <div style={cells("2_3-4")} class="horizontal-dotted-line lines"></div>
        <div style={cells("5-6_2")} class="vertical-line lines {memoryProgress >= 8 ? 'memory-yellow' : 'gray'}"></div>
        <div style={cells("6-7_1")} class="vertical-line lines {memoryProgress >= 10 ? 'memory-yellow' : 'gray'}"></div>
        <div style={cells("2-3_3")} class="vertical-dotted-line lines"></div>
        <div style={cells("7_1-2")} class="horizontal-line lines {memoryProgress >= 11 ? 'memory-yellow' : 'gray'}"></div>
        <div style={cells("7-8_2")} class="vertical-line lines {memoryProgress >= 12 ? 'memory-yellow' : 'gray'}"></div>
        <div style={cells("8_1-2")} class="horizontal-line lines {memoryProgress >= 13 ? 'memory-yellow' : 'gray'}"></div>

        <div style={cells("2_4-5")} class="horizontal-line lines {mentalMathProgress >= 1 ? 'mental-blue' : 'gray'}"></div>
        <div style={cells("2_5-6")} class="horizontal-line lines {mentalMathProgress >= 2 ? 'mental-blue' : 'gray'}"></div>
        <div style={cells("2_6-7")} class="horizontal-line lines {mentalMathProgress >= 3 ? 'mental-blue' : 'gray'}"></div>
        <div style={cells("2-3_7")} class="vertical-line lines {mentalMathProgress >= 4 ? 'mental-blue' : 'gray'}"></div>
        <div style={cells("3_6-7")} class="horizontal-line lines {mentalMathProgress >= 5 ? 'mental-blue' : 'gray'}"></div>
        <div style={cells("3_5-6")} class="horizontal-line lines {mentalMathProgress >= 6 ? 'mental-blue' : 'gray'}"></div>
        <div style={cells("3-4_5")} class="vertical-line lines {mentalMathProgress >= 7 ? 'mental-blue' : 'gray'}"></div>
        <div style={cells("4_5-6")} class="horizontal-line lines {mentalMathProgress >= 8 ? 'mental-blue' : 'gray'}"></div>
        <div style={cells("4_6-7")} class="horizontal-line lines {mentalMathProgress >= 9 ? 'mental-blue' : 'gray'}"></div>
        <div style={cells("4-5_7")} class="vertical-line lines {mentalMathProgress >= 10 ? 'mental-blue' : 'gray'}"></div>
        <div style={cells("5_6-7")} class="horizontal-line lines {mentalMathProgress >= 11 ? 'mental-blue' : 'gray'}"></div>
        <div style={cells("5_5-6")} class="horizontal-line lines {mentalMathProgress >= 12 ? 'mental-blue' : 'gray'}"></div>
        <div style={cells("5-6_5")} class="vertical-line lines {mentalMathProgress >= 13 ? 'mental-blue' : 'gray'}"></div>
        <div style={cells("6_5-6")} class="horizontal-line lines {mentalMathProgress >= 14 ? 'mental-blue' : 'gray'}"></div>
        <div style={cells("6_6-7")} class="horizontal-line lines {mentalMathProgress >= 15 ? 'mental-blue' : 'gray'}"></div>
        <div style={cells("6-7_7")} class="vertical-line lines {mentalMathProgress >= 16 ? 'mental-blue' : 'gray'}"></div>
        <div style={cells("7_6-7")} class="horizontal-line lines {mentalMathProgress >= 17 ? 'mental-blue' : 'gray'}"></div>
        <div style={cells("7_5-6")} class="horizontal-line lines {mentalMathProgress >= 18 ? 'mental-blue' : 'gray'}"></div>
        <div style={cells("7-8_5")} class="vertical-line lines {mentalMathProgress >= 19 ? 'mental-blue' : 'gray'}"></div>
        <div style={cells("8_5-6")} class="horizontal-line lines {mentalMathProgress >= 20 ? 'mental-blue' : 'gray'}"></div>
        <div style={cells("8_6-7")} class="horizontal-line lines {mentalMathProgress >= 21 ? 'mental-blue' : 'gray'}"></div>
        <div style={cells("8-9_7")} class="vertical-line lines {mentalMathProgress >= 22 ? 'mental-blue' : 'gray'}"></div>
        <div style={cells("9_6-7")} class="horizontal-line lines {mentalMathProgress >= 23 ? 'mental-blue' : 'gray'}"></div>
        <div style={cells("9_5-6")} class="horizontal-line lines {mentalMathProgress >= 24 ? 'mental-blue' : 'gray'}"></div>
        <div style={cells("9-10_5")} class="vertical-line lines {mentalMathProgress >= 25 ? 'mental-blue' : 'gray'}"></div>
        <div style={cells("10_5-6")} class="horizontal-line lines {mentalMathProgress >= 26 ? 'mental-blue' : 'gray'}"></div>
        <div style={cells("10_6-7")} class="horizontal-line lines {mentalMathProgress >= 27 ? 'mental-blue' : 'gray'}"></div>
        <div style={cells("10-11_7")} class="vertical-line lines {mentalMathProgress >= 28 ? 'mental-blue' : 'gray'}"></div>
        <div style={cells("11_6-7")} class="horizontal-line lines {mentalMathProgress >= 29 ? 'mental-blue' : 'gray'}"></div>
        <div style={cells("11_5-6")} class="horizontal-line lines {mentalMathProgress >= 30 ? 'mental-blue' : 'gray'}"></div>
        <div style={cells("11_4-5")} class="horizontal-line lines {mentalMathProgress >= 31 ? 'mental-blue' : 'gray'}"></div>
        <div style={cells("11_3-4")} class="horizontal-line lines {mentalMathProgress >= 32 ? 'mental-blue' : 'gray'}"></div>
        <div style={cells("5-6_2")} class="vertical-yellow-line lines"></div>
        <div style={cells("6-7_1")} class="vertical-yellow-line lines"></div>
        <div style={cells("7-8_2")} class="vertical-yellow-line lines"></div>

        <div style={cells("3_3-4")} class="horizontal-line lines {appliedMathProgress >= 1 ? 'applied-green' : 'gray'}"></div>
        <div style={cells("4_3-4")} class="horizontal-line lines {appliedMathProgress >= 3 ? 'applied-green' : 'gray'}"></div>
        <div style={cells("5_3-4")} class="horizontal-line lines {appliedMathProgress >= 5 ? 'applied-green' : 'gray'}"></div>
        <div style={cells("6_3-4")} class="horizontal-line lines {appliedMathProgress >= 7 ? 'applied-green' : 'gray'}"></div>
        <div style={cells("7_3-4")} class="horizontal-line lines {appliedMathProgress >= 9 ? 'applied-green' : 'gray'}"></div>
        <div style={cells("8_3-4")} class="horizontal-line lines {appliedMathProgress >= 11 ? 'applied-green' : 'gray'}"></div>
        <div style={cells("9_3-4")} class="horizontal-line lines {appliedMathProgress >= 13 ? 'applied-green' : 'gray'}"></div>
        <div style={cells("10_3-4")} class="horizontal-line lines {appliedMathProgress >= 15 ? 'applied-green' : 'gray'}"></div>
        <div style={cells("3-4_4")} class="vertical-line lines {appliedMathProgress >= 2 ? 'applied-green' : 'gray'}"></div>
        <div style={cells("4-5_3")} class="vertical-line lines {appliedMathProgress >= 4 ? 'applied-green' : 'gray'}"></div>
        <div style={cells("5-6_4")} class="vertical-line lines {appliedMathProgress >= 6 ? 'applied-green' : 'gray'}"></div>
        <div style={cells("6-7_3")} class="vertical-line lines {appliedMathProgress >= 8 ? 'applied-green' : 'gray'}"></div>
        <div style={cells("7-8_4")} class="vertical-line lines {appliedMathProgress >= 10 ? 'applied-green' : 'gray'}"></div>
        <div style={cells("8-9_3")} class="vertical-line lines {appliedMathProgress >= 12 ? 'applied-green' : 'gray'}"></div>
        <div style={cells("9-10_4")} class="vertical-line lines {appliedMathProgress >= 14 ? 'applied-green' : 'gray'}"></div>
    </div>
</div>

<style>
    p {
        bottom: 1px;
    }
    .progress-chart {
        display: flex;
        position: absolute;
        left: 0px;
        height: 100vh;
        color: black;
    }

    .grid-container {
        display: grid;
        grid-template-columns: repeat(7, 100px);
        grid-template-rows: repeat(11, 100px);
    }

    .yellow {
        background-color: rgb(255, 171, 2);
    }
    .green {
        background-color: rgb(0, 207, 107);
    }
    .blue {
        background-color: rgb(0, 56, 210);
    }
    .whitebox {
        display: flex;
        background-color: #ffffff;
        border: 1px solid #c0c0c0;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;
    }

    .whitebox p {
        position: absolute;
        font-size: 8px;
        font-weight: bold;
        text-align: bottom;
    }

    .graybox {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: rgb(216, 216, 216);
        position: relative; /*makes the title bottom: 1px work */
    }

    .lines {
        box-shadow: 0 3px 8px rgba(0, 0, 0, 0.35);
    }

    /* .line {
        width: 100px;
        height: 5px;
        top: 50px;
        left: 40px;
        background-color: #e0002d;
        text-decoration: none;
        position: relative;
    } */

    .red {
        background-color: #e0002d;
        z-index: 2;
    }

    .horizontal-line {
        width: 100px;
        height: 5px;
        top: 50px;
        left: 50px;
        text-decoration: none;
        position: relative;
    }

    /* .big-circle1 {
        width: 45px;
        height: 45px;
        border-radius: 50%;
        text-decoration: none;
        position: relative;
    } */

    .memory-button {
        width: 50px;
        height: 50px;
        border-radius: 5%;
        text-decoration: none;
        position: relative;
    }

    .applied-button {
        width: 50px;
        height: 50px;
        border-radius: 5%;
        text-decoration: none;
        position: relative;
        z-index: 2;
    }
    .horizontal-dotted-line {
        width: 100px;
        height: 3px;
        top: 50px;
        left: 40px;
        position: relative;
        border-top: 4px dotted #000000;
    }
    .vertical-dotted-line {
        width: 3px;
        height: 100px;
        top: 50px;
        left: 48px;
        position: relative;
        border-left: 4px dotted #000000;
    }

    .vertical-line {
        width: 5px;
        height: 100px;
        top: 50px;
        left: 48px;
        text-decoration: none;
        position: relative;
    }

    .mental-button {
        width: 50px;
        height: 50px;
        border-radius: 5%;
        text-decoration: none;
        position: relative;
        z-index: 12;
    }
    /* .mental-button:hover .info-window {
        display: block;
    } */

    .vertical-line {
        width: 5px;
        height: 105px;
        top: 50px;
        left: 48px;
        text-decoration: none;
        position: relative;
    }

    /* .horizontal-green-line {
        width: 100px;
        height: 5px;
        top: 50px;
        left: 50px;
        background-color: rgb(0, 207, 107);
        text-decoration: none;
        position: relative;
    }
    .vertical-green-line {
        width: 5px;
        height: 105px;
        top: 50px;
        left: 48px;
        background-color: rgb(0, 207, 107);
        text-decoration: none;
        position: relative;
    } */
    /* .square-button {
        position: relative;
        z-index: 10;
    }
    .square-button:hover .info-window {
        display: block;
    } */
    .task {
        position: relative;
        display: flex;
        flex-direction: column;
    }
    .info-window {
        display: none;
        position: absolute;
        background-color: black;
        color: gold;
        padding: 10px;
        margin-left: 10px;
        width: 290px;
        height: 100px;
        border: 1px solid yellow;
        border-radius: 5px;
        left: 100%;
        top: -20px;
    }
    .child-text {
        display: flex;
        flex-direction: column;
        position: relative;
        justify-content: left;
        align-items: center;
        top: 0px;
    }
    .content {
        display: flex;
        float: row;
        justify-content: center;
        align-items: center;
    }
    .heading {
        font-size: 10px;
    }
    .text-b {
        text-align: left;
        font-size: 10px;
        width: 230px;
        color: white;
    }
    .continue-btn {
        width: 60px;
        height: 20px;
        font-size: 11px;
        margin-top: 40px;
        margin-left: -160px;
    }
    .continue-btn:hover {
        color: white;
        animation: glow-border 0.5s infinite alternate;
    }
    @keyframes glow-border {
        0% {
            border-color: gold;
            box-shadow: 0 0 10px gold;
        }
        100% {
            border-color: white;
            box-shadow: 0 0 20px gold;
        }
    }
</style>
