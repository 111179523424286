<script lang="ts">
    //Sounds.svelte
    import type { SocketAddress } from "net";
    import { volume } from "./services/stores";

    let isMuted = false;
    //let volume: number = 100;
    $: console.log($volume);

    function toggleSound(): void {
        isMuted = !isMuted;
        if (isMuted) {
            $volume = 0;
        } else {
            $volume = 0.5;
        }
    }
    function adjustVolume(event: Event): void {
        const volumeSlider = event.target as HTMLInputElement;
        $volume = parseFloat(volumeSlider.value);
        if ($volume === 0) {
            isMuted = true;
        } else {
            isMuted = false;
        }
    }
</script>

<div class="settings-page">
    <h2>Settings</h2>
    
    <div class="volume-settings" id="volumeControl" style="display: block;">
        <div class="title">Sound:</div>
        <button id="soundToggle" type="button" on:click={toggleSound} aria-label={isMuted ? "Unmute sound" : "Mute sound"}>
            <img alt="Sound Toggle" src={isMuted ? "/mute.svg" : "/unmute.svg"} width="30px" height="30px" />
        </button>
        <input type="range" id="volumeSlider" min="0" max="1" step="0.01" bind:value={$volume} on:input={adjustVolume} />
    </div>
    <h3>Profile</h3>
    <h3>Language</h3>
    <h3>Privacy Policy</h3>
    <h3>Level Select</h3>
    <h3>Invite Friends</h3>

</div>

<style>
    #soundToggle {
        width: 30px;
        height: 30px;
        background: none;
        border: none;
        padding: 0;
        cursor: pointer;
    }
    .settings-page {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    .volume-settings {
        margin: 10px 0;
    }
    input[type="range"]::-webkit-slider-runnable-track {
        width: 200px;
    }
    input[type="range"] {
        vertical-align: middle;
        margin-bottom: 22px;
    }
    .title {
        display: flex;
    }
</style>
