<!-- Keypadmem.svelte -->

<script lang="ts">
	//We could have to modes Learn and Test. For the test we run the mentalmath type of game, with max 3 errors
	//Maybe we could also start in "Test mode" and progress to "Learn mode" If we make mistakes.

	import "../Funnel.css";
	import { fetchGet, fetchPost } from "../services/fetch";
	import { isMobile } from "../services/stores";
	import { mathContent } from "../services/stores";
	import { Memory, BatchMemoryTask, BatchLessonData, MemoryMathTask, Contact, type IMemory } from "../models/interfaces"; //TODO try to get rid of some of these
	import { navigate } from "svelte-routing";
	import { onMount, onDestroy } from "svelte";
	import { tick } from "svelte";
	import { toast } from "@zerodevx/svelte-toast";
	import NewGame from "../GameUnlocked.svelte";
	import SwitchButton from "../SwitchButton.svelte";
	import type { SvelteComponent } from "svelte";

	export let slug: any;

	//let currentTasks: BatchMemoryTasksVm | null;
	//let tasks: BatchMemoryTask[] | undefined = [];
	let available_questions = 0;
	let comingUp = 0;
	let currentTask: BatchMemoryTask | null;
	let finished = false; //More questions
	let hintExists = false;
	let infoText = "";
	let inLongTermMemory: number = 0;
	let inputElement: HTMLInputElement | null = null;
	let InstructionComponent: typeof SvelteComponent | null = null;
	let isGameOver = false;
	let isShowHighScore = false;
	let isShowHighscoreButton = true;
	let lessonDescription = "Loading";
	let memories: Memory[] = [];
	let notRemembered: number = 0;
	let paramA: string = "-1";
	let paramB: string = "-1";
	let questionStr: string | undefined = "";
	let remembered10Minute: number = 0;
	let remembered1day: number = 0;
	let remembered1hour: number = 0;
	let remembered1Minute: number = 0;
	let remembered1month: number = 0;
	let remembered1week: number = 0;
	let remembered2Minute: number = 0;
	let remembered30Minute: number = 0;
	let remembered5Minute: number = 0;
	let repeatNext10min: number = 0;
	let repeatNext1D: number = 0;
	let repeatNext1H: number = 0;
	let repeatNext1M: number = 0;
	let repeatNext1min: number = 0;
	let repeatNext1W: number = 0;
	let repeatNext2min: number = 0;
	let repeatNext30min: number = 0;
	let repeatNext5min: number = 0;
	let secondsRemembered: number = 0;
	let showInstructions = true;
	let showQuestionNotSolution = true;
	let started = false;
	let tasks: BatchMemoryTask[] = [];
	let tasksLeft = 0;
	let title = "";
	let userinput = "";

	$: handleRouteChange(slug);
	$: console.log("started " + started);
	$: console.log("showInstructions " + showInstructions);
	$: console.log("isShowHighscore: " + isShowHighScore);
	$: console.log(`showHighscoreButton: ${isShowHighscoreButton}`);
	$: console.log(`showHighscoreButton2: ${isShowHighscoreButton}`);
	$: console.log(`InstructionComponent?=${InstructionComponent ? true : false} showInstructions=${showInstructions} started=${started}`);
	$: console.log("notRemembered 2" + notRemembered);
	$: console.log("secondsRemembered: " + secondsRemembered);
	$: console.log("currentTask", currentTask);
	$: console.log("finished: :" + finished);
	$: if (started && showQuestionNotSolution && inputElement) focusInput();
	$: console.log("hints exist :" + hintExists);
	$: comingUp = tasksLeft + available_questions;

	$: if (questionStr) {
		console.log("new questionstring: " + questionStr);
		mathContent.set(questionStr);
		updateMathJax();
	}

	async function updateMathJax() {
		if (typeof window !== "undefined" && window.MathJax) {
			await window.MathJax.typesetPromise();
		}
		await tick();

		if (window.MathJax) {
			await window.MathJax.typesetPromise();
		}
	}

	async function handleRouteChange(slug: string) {
		focusInput();
		await setCurrentLesson(slug);
		loadHints();
	}

	const showDataNumber = remembered1month > 0;

	interface LessonVM {
		name: string;
		title: string;
		description: string;
	}

	//using the mouse/fingers on the screen elements
	function on1button() {
		userinput += "1";
	}
	function on2button() {
		userinput += "2";
	}
	function on3button() {
		userinput += "3";
	}
	function on4button() {
		userinput += "4";
	}
	function on5button() {
		userinput += "5";
	}
	function on6button() {
		userinput += "6";
	}
	function on7button() {
		userinput += "7";
	}
	function on8button() {
		userinput += "8";
	}
	function on9button() {
		userinput += "9";
	}
	function on0button() {
		userinput += "0";
	}
	function onCommabutton() {
		userinput += ".";
	}
	function onClear(event: MouseEvent & { currentTarget: EventTarget & HTMLButtonElement }) {
		if (userinput.length > 0) {
			userinput = userinput.substring(0, userinput.length - 1);
		}
	}

	//Would be better that this is active only if this component is visible
	function onEnter() {
		userinput = userinput.replaceAll(",", ".");

		if (userinput.length > 0) {
			console.log();
			if (userinput.charAt(0) == "." || userinput.charAt(0) == ",") {
				userinput = "0" + userinput;
			}
		}

		if (started) {
			if (userinput == "") return;
			CheckAnswer();
		} else {
			StartGame();
		}
	}

	async function loadHints() {
		hintExists = false;
		try {
			const componentMap: any = {
				mathconstants3digits: () => import("../instructions/memory/Constants.svelte"),
			};

			if (componentMap[slug]) {
				let module1 = componentMap[slug];
				console.log("Importing component module:", module1);

				const module = await componentMap[slug]();
				console.log("Imported component module:", module);
				InstructionComponent = module.default;
				console.log("InstructionComponent set to:", InstructionComponent); // Log the component itself
				hintExists = true;
			} else {
				console.error("Component not found for slug:", slug);
			}
		} catch (error) {
			console.error("Error loading component:", error);
		}
	}

	function NextTask() {
		currentTask = GetNextTask();

		showCurrentTask();
	}

	function StartGame() {
		NextTask();

		started = true;

		setTimeout(() => {
			focusInput();
		}, 0);
	}

	function CheckAnswer() {
		let IsCorrect = false;
		if (currentTask != null && currentTask!) {
			if (userinput == currentTask.answer) {
				toast.push("Correct " + currentTask.answer, {});
				ItsCorrect(currentTask.id);
				IsCorrect = true;
			} else {
				const toastId = toast.push("Should be " + currentTask.answer + " for " + currentTask.question, {
					classes: ["error"],
					initial: 0,
					dismissable: false, // Adds a close button
				});
				setTimeout(() => {
					toast.pop(toastId);
				}, 3000);
				ItsWrong(currentTask.id);
			}
			userinput = "";
		}
	}

	function Correct(translationId: number) {
		let memory = memories.find((mem) => mem.translation.id === translationId);
		if (!memory) {
			throw new Error("No memory of this word");
		}
		let now = new Date();
		let timeDiffSeconds = (now.getTime() - new Date(memory.seen).getTime()) / 1000;
		memory.secondsRemembered += timeDiffSeconds;
		memory.seen = now;
		currentTask = GetNextTask();
		showCurrentTask();
	}

	function Wrong(translationId: number) {
		let memory = memories.find((mem) => mem.translation.id === translationId);
		if (!memory) {
			throw new Error("No memory of this word");
		}
		memory.secondsRemembered = 0;
		memory.seen = new Date();
		currentTask = GetNextTask();
		showCurrentTask();
	}

	function ItsCorrect(id: number) {
		Correct(id);
	}

	async function ItsWrong(id: number) {
		showQuestionNotSolution = false;
		progressBarWidth = "0%";
		await tick(); // Let Svelte update the DOM
		progressBarWidth = "100%";
		setTimeout(() => {
			showQuestionNotSolution = true;
			progressBarWidth = "0%";
			focusInput();
		}, 3000); // Adjust to match the CSS animation duration

		Wrong(id);
	}

	function showCurrentTask() {
		console.log("**showCurrentTask");
		// //showInstructions = false;
		if (currentTask) {
			questionStr = currentTask.question;
			if (questionStr) {
				const splitArray = questionStr.split("\n");
				questionStr = splitArray[0];
				if (splitArray.length == 2) infoText = splitArray[1];
			} else {
				questionStr = "";
				finished = true;
				started = false;
			}
		}
		// notRemembered = currentTask.available_questions;
		// secondsRemembered = currentTask.points;
		// repeatNext1min = currentTask.repeatNext1min;
		// repeatNext2min = currentTask.repeatNext2min;
		// repeatNext5min = currentTask.repeatNext5min;
		// repeatNext10min = currentTask.repeatNext10min;
		// repeatNext30min = currentTask.repeatNext30min;
		// repeatNext1H = currentTask.repeatNext1H;
		// repeatNext1D = currentTask.repeatNext1D;
		// repeatNext1W = currentTask.repeatNext1W;
		// repeatNext1M = currentTask.repeatNext1M;
		// remembered1Minute = currentTask.remembered1Minute;
		// remembered2Minute = currentTask.remembered2Minute;
		// remembered5Minute = currentTask.remembered5Minute;
		// remembered10Minute = currentTask.remembered10Minute;
		// remembered30Minute = currentTask.remembered30Minute;
		// remembered1hour = currentTask.remembered1hour;
		// remembered1day = currentTask.remembered1day;
		// remembered1week = currentTask.remembered1week;
		// remembered1month = currentTask.remembered1month;
		// inLongTermMemory = currentTask.inLongTermMemory;
		// achievedNewProgress = currentTask.achievedNewProgress;
		// tasksLeft = currentTask.tasksLeft; //not yet askede
		// comingUp = currentTask.tasksLeft + currentTask.available_questions;
	}

	onMount(() => {
		window.addEventListener("keyup", globalKeyupHandler);
	});

	onDestroy(() => {
		window.removeEventListener("keyup", globalKeyupHandler);

		//Save progress


	});

	function globalKeyupHandler(event: KeyboardEvent) {
		if (event.key === "Enter") {
			onEnter();
		}
	}

	let progressBarWidth = "0%"; // Start with the progress bar empty

	async function setCurrentLesson(slug: string): Promise<void> {
		var clientUrl = "/BatchCrammer/SetCurrentLesson/" + slug; //should be the same as for crammer
		await fetchGet<void>(clientUrl);

		//get title and description

		clientUrl = "/BatchCrammer/GetCurrentLesson/" + slug;
		let currentLesson = await fetchGet<LessonVM>(clientUrl);

		lessonDescription = currentLesson.description;
		title = currentLesson.title;

		clientUrl = "/BatchCrammer/GetLesson/" + slug;
		let lessonData = await fetchGet<BatchLessonData>(clientUrl);
		if (lessonData.translations) {
			tasks = lessonData.translations;
		}
		if (lessonData.memories != undefined) memories = lessonData.memories;
	}

	function focusInput() {
		if (inputElement) {
			inputElement.focus();
			//console.log("Focus attempted on inputElement.");
		} else {
			//console.log("inputElement is not available.");
		}
	}
	function restart(): any {
		location.reload();
	}

	function showHints(): any {
		showInstructions = true;
	}

	async function forgetLesson(event: MouseEvent & { currentTarget: EventTarget & HTMLButtonElement }) {
		var clientUrl = "/BatchCrammer/ForgetLesson/";
		await fetchGet<void>(clientUrl);
		location.reload();
	}

	function GetNextTask(): BatchMemoryTask | null {
		let memTask: BatchMemoryTask;
		const now = Date.now(); // Current time in milliseconds

		if (memories == null || memories == undefined) return null;

		// Filter old memories
		let oldMemories = memories
			.filter((x: Memory) => (now - new Date(x.seen).getTime()) / 1000 > 2 * x.secondsRemembered)
			.map((mem: Memory) => {
				const seconds = (now - new Date(mem.seen).getTime()) / 1000;
				const rememberedPlus1 = mem.secondsRemembered + 1;
				mem.sortVal = seconds / rememberedPlus1;
				return mem;
			})
			.sort((a: Memory, b: Memory) => b.sortVal - a.sortVal);

		let count = oldMemories.length;
		let memory = oldMemories[0];

		memTask = new BatchMemoryTask();
		if (memory) {
			memTask.question = memory.translation.task;
			memTask.answer = memory.translation.solved;
			memTask.id = memory.translation.id;
		} else {
			// No old memory, look for a new task
			const lessonMemoriesTasks = memories.map((x) => x.translation);
			//const nextNew = tasks.filter((mmt) => !lessonMemoriesTasks.includes(mmt))[0];
			const newTasks = tasks.filter((mmt) => !lessonMemoriesTasks.find((lmt) => lmt.id == mmt.id));

			let hasNew = newTasks.length > 0;
			let nextNew = newTasks[0];

			if (hasNew) {
				let newTrans = new MemoryMathTask();
				newTrans.id = nextNew.id;
				newTrans.solved = nextNew.answer;
				newTrans.task = nextNew.question;

				memTask.question = nextNew.question;
				memTask.answer = nextNew.answer;
				memTask.id = nextNew.id;

				let memoryData: IMemory = {
					secondsRemembered: 30 * 60, // Assume remembered for 30 minutes
					seen: new Date(),
					sortVal: 0,
					translation: newTrans,
					id: newTrans.id,
					contact: new Contact(), //not in use
					contactId: 0,
					sortOrder: 0,
				};
				let newMemory = Memory.fromJS(memoryData);

				memories.push(newMemory); // Add the new memory
			} else {
				//memTask = new BatchMemoryTask();
				//memTask.achievedNewProgress = lessonProgression(contact); // Update lesson progress
			}
		}

		available_questions = count;
		notRemembered = available_questions;
		let points = memories.reduce((sum, x) => sum + x.secondsRemembered, 0);
		secondsRemembered = points;

		let tasksAheadSoFar = { value: 0 };
		repeatNext1min = countTasksAhead(memories, 1 * 60 * 1000, tasksAheadSoFar);
		repeatNext2min = countTasksAhead(memories, 2 * 60 * 1000, tasksAheadSoFar);
		repeatNext5min = countTasksAhead(memories, 5 * 60 * 1000, tasksAheadSoFar);
		repeatNext10min = countTasksAhead(memories, 10 * 60 * 1000, tasksAheadSoFar);
		repeatNext30min = countTasksAhead(memories, 30 * 60 * 1000, tasksAheadSoFar);
		repeatNext1H = countTasksAhead(memories, 60 * 60 * 1000, tasksAheadSoFar);
		repeatNext1D = countTasksAhead(memories, 24 * 60 * 60 * 1000, tasksAheadSoFar);
		repeatNext1W = countTasksAhead(memories, 7 * 24 * 60 * 60 * 1000, tasksAheadSoFar);
		repeatNext1M = countTasksAhead(memories, 30 * 24 * 60 * 60 * 1000, tasksAheadSoFar);

		let rememberedCountSoFar = { value: 0 };
		inLongTermMemory = countRememberedAtLeastSeconds(memories, 60 * 24 * 60 * 60, rememberedCountSoFar);
		remembered1month = countRememberedAtLeastSeconds(memories, 30 * 24 * 60 * 60, rememberedCountSoFar);
		remembered1week = countRememberedAtLeastSeconds(memories, 7 * 24 * 60 * 60, rememberedCountSoFar);
		remembered1day = countRememberedAtLeastSeconds(memories, 24 * 60 * 60, rememberedCountSoFar);
		remembered1hour = countRememberedAtLeastSeconds(memories, 60 * 60, rememberedCountSoFar);
		remembered30Minute = countRememberedAtLeastSeconds(memories, 30 * 60, rememberedCountSoFar);
		remembered10Minute = countRememberedAtLeastSeconds(memories, 10 * 60, rememberedCountSoFar);
		remembered5Minute = countRememberedAtLeastSeconds(memories, 5 * 60, rememberedCountSoFar);
		remembered2Minute = countRememberedAtLeastSeconds(memories, 2 * 60, rememberedCountSoFar);
		remembered1Minute = countRememberedAtLeastSeconds(memories, 60, rememberedCountSoFar);

		// Tasks left in lesson
		const tasksInLesson = tasks.length;
		let taskIds = tasks.map((t) => t.id);
		//let memoriesInLesson = memories.filter((mem) => taskIds.includes(mem.id));
		let memoriesInLesson = memories.filter((mem) => taskIds.includes(mem.translation.id));

		tasksLeft = tasksInLesson - memoriesInLesson.length;

		return memTask;
	}

	function countTasksAhead(memories: Memory[], periodAhead: number, tasksAheadSoFar: { value: number }): number {
		const now = Date.now();

		const tasksInPeriod =
			memories.filter((x: Memory) => {
				const seenTime = new Date(x.seen).getTime();
				return now + periodAhead - seenTime > 2 * x.secondsRemembered * 1000;
			}).length - tasksAheadSoFar.value;

		tasksAheadSoFar.value += tasksInPeriod;

		return tasksInPeriod;
	}

	function countRememberedAtLeastSeconds(memories: Memory[], secondsRemembered: number, rememberedSoFar: { value: number }): number {
		const remembered = memories.filter((x: Memory) => x.secondsRemembered > secondsRemembered).length - rememberedSoFar.value;

		rememberedSoFar.value += remembered;

		return remembered;
	}
</script>

<!-- <div>
	manually inserted DebugConst
	<DebugConst></DebugConst>
	end of manually inserted DebugConst
</div> -->

<div class="score-button">
	<button on:click={() => navigate("/home")} class="bttn">
		<img
			alt="home"
			src="/homeicon.svg"
			style="display: inline-block; vertical-align: middle; background-color:white; margin-bottom:2px;"
			width="20px"
			height="20px"
		/>Home
	</button>
	<button on:click={() => restart()} class="bttn">
		<img
			alt="restart"
			src="/restart.svg"
			style="display: inline-block; vertical-align: middle;"
			width="20px"
			height="20px"
		/>Restart</button
	>
	<button on:click={forgetLesson} class="forget-lesson bttn">Forget Lesson</button>
</div>

<NewGame></NewGame>

{#if finished}
	<div style="color:red; font-size: 12px;">
		No more questions for now. Need to wait to transfer into long term memory or check memories in long term memomry
	</div>
	<br />
{/if}

<!-- <h2>Coming up: {comingUp}</h2> -->
<div class="stair-keypad">
	<div class="task-left">
		<!-- <div class="task-left">
		{#if tasksLeft}
			<h>Tasks left in challenge: {tasksLeft}</h>
		{/if}
	 </div> -->
		<div class="to-do">
			<div class="task">
				<!-- <div>
					<div
						style="width: 60px; margin-left: 140px"
						class="box"
						data-number={remembered1week}
					></div>
					<div
						style="width: 80px; margin-left: 120px"
						class="box"
						data-number={remembered1day}
					></div>
					<div
						style="width: 100px; margin-left: 100px"
						class="box"
						data-number={remembered1hour}
					></div>
					<div
						style="width: 120px; margin-left: 80px"
						class="box"
						data-number={remembered30Minute}
					></div>

					<div
						style="width: 140px;  margin-left: 60px"
						class="box"
						data-number={remembered10Minute}
					></div>
					<div
						style="width: 160px; margin-left: 40px"
						class="box"
						data-number={remembered5Minute}
					></div>

					<div
						style="width: 180px; "
						class="box"
						data-number={remembered2Minute}
					></div>
					<div
						style="width: 200px;"
						class="box"
						data-number={remembered1Minute}
					></div>
				</div> -->

				<div class="words">
					<img alt="icon" src="/stairs.svg" class="stairs" style="" />
					<div class="time1 onemonth date">
						{#if remembered1month}
							<span>{remembered1month}</span>
						{/if}
					</div>
					<div class="time1 oneweek date">
						{#if remembered1week}
							<span>{remembered1week}</span>
							<span>{remembered1week}</span>
						{/if}
					</div>
					<div class="time1 oneday date">
						{#if remembered1day}
							<span>{remembered1day}</span>
							<span>{remembered1day}</span>
						{/if}
					</div>
					<div class="time1 onehour date">
						{#if remembered1hour}
							<span>{remembered1hour}</span>
						{/if}
					</div>
					<div class="time1 thirtymin date">
						{#if remembered30Minute}
							<span>{remembered30Minute}</span>
						{/if}
					</div>
					<div class="time1 tenmin date">
						{#if remembered10Minute}
							<span>{remembered10Minute}</span>
						{/if}
					</div>
					<div class="time1 fivemin date">
						{#if remembered5Minute}
							<span>{remembered5Minute}</span>
						{/if}
					</div>
					<div class="time1 twomin date">
						{#if remembered2Minute}
							<span>{remembered2Minute}</span>
						{/if}
					</div>
					<div class="time1 onemin date">
						{#if remembered1Minute}
							<span>{remembered1Minute}</span>
						{/if}
					</div>
					<div class="time1 one date"></div>
				</div>
			</div>
			<div class="repeat-available">
				<div class="repeat">
					<img alt="icon" src="/funnel.svg" class="funnel" style="" />
					{#if inLongTermMemory}
						<div style="margin-top:-33px" class="time longterm">
							<div>{inLongTermMemory}</div>
							<p class="repeat-time">longTermMemory</p>
						</div>
					{/if}
					{#if repeatNext1M}
						<div style="margin-top:-25px" class="time one-month">
							<p class="repeat-time">repeatNext1M</p>
							<div style="font-size: 10px;">{repeatNext1M}</div>
						</div>
					{/if}

					{#if repeatNext1W}
						<div style="margin-top:-23px" class="time one-week">
							<div style="font-size: 10px;">{repeatNext1W}</div>
							<p class="repeat-time">repeatNext1W</p>
						</div>
					{/if}

					{#if repeatNext1D}
						<div style="margin-top:-18px" class="time one-day">
							<p class="repeat-time">repeatNext1D</p>
							<div style="font-size: 10px;">{repeatNext1D}</div>
						</div>
					{/if}
					{#if repeatNext1H}
						<div style="margin-top:-11px" class="time one-hour">
							<div style="font-size: 10px;">{repeatNext1H}</div>
							<p class="repeat-time">repeatNext1H</p>
						</div>
					{/if}
					{#if repeatNext30min}
						<div style="margin-top:-5px" class="time thirty-min">
							<p class="repeat-time">repeatNext30min</p>
							<div style="font-size: 10px;">
								{repeatNext30min}
							</div>
						</div>
					{/if}
					{#if repeatNext10min}
						<div style="margin-top:1px" class="time ten-min">
							<div style="font-size: 10px;">
								{repeatNext10min}
							</div>
							<p class="repeat-time">repeatNext10minutes</p>
						</div>
					{/if}
					{#if repeatNext5min}
						<div style="margin-top:8px" class="time five-min">
							<p class="repeat-time">repeatNext5minutes</p>
							<div style="font-size: 10px;">{repeatNext5min}</div>
						</div>
					{/if}
					{#if repeatNext2min}
						<div style="margin-top:18px" class="time two-min">
							<div style="font-size: 10px;">{repeatNext2min}</div>
							<p class="repeat-time">repeatNext2minutes</p>
						</div>
					{/if}
					<div style="margin-top:30px" class="time one-min">
						<p class="repeat-time">repeatNext1minute</p>
						<div style="font-size: 10px;">{repeatNext1min}</div>
					</div>
				</div>
				<div class="available">
					{#if notRemembered}<div>
							Repeatable: {notRemembered}
						</div>
					{/if}
				</div>
			</div>
		</div>
	</div>
	{#if isShowHighScore}
		<!-- <HiScore {slug}></HiScore> -->
	{/if}
	

	<!-- isMobile: {$isMobile} -->
	<div class="content">
		
		<div class="game">
			{#if isGameOver}
				<!-- <GameOver {results}></GameOver> -->
			{:else}
				<div class="keypad">
					<div>
						<SwitchButton></SwitchButton>
						<div class="level">
							<h1 class="custom-h1">
								<p class="title">{lessonDescription}</p>
							</h1>
						</div>
						<div class="keypad-content">
							<div class="flex-box2">
								<div class="task-timer">
									<div>Coming up: {comingUp}</div>
								</div>
							</div>
							<div class="container">
								<div>
									<div class="background">
										{#if started}
											<div>
												<div class="flex-box">
													<div class="small-font">{@html $mathContent}</div>
													
												</div>
												<div class="info-text">
													{infoText}
												</div>
											</div>
										{:else}
											<div class="flex-box">Press "Enter" to start</div>
											<div class="info-text">
												{infoText}
											</div>
										{/if}
									</div>
								</div>

								<div class="input-container">
									{#if started}
										{#if showQuestionNotSolution}
											<input
												type="text"
												class="my-input"
												bind:value={userinput}
												bind:this={inputElement}
												readonly={$isMobile}
											/>
										{:else}
											<div class="correct-hint" id="correctHint">
												<div class="solution">
													{#if currentTask}
														{currentTask.answer}
													{/if}
												</div>
												<div class="progress-container">
													<div class="progress-bar" style="width: {progressBarWidth};"></div>
												</div>
											</div>
										{/if}
									{/if}
								</div>
							</div>
							<div class="flex-box1">
								<div class="grid-container">
									<button on:click={on7button} class="grid-item7 custom-button">7</button>
									<button on:click={on8button} class="grid-item8 custom-button">8</button>
									<button on:click={on9button} class="grid-item9 custom-button">9</button>
									<button on:click={on4button} class="grid-item4 custom-button">4</button>
									<button on:click={on5button} class="grid-item5 custom-button">5</button>
									<button on:click={on6button} class="grid-item6 custom-button">6</button>
									<button on:click={on1button} class="grid-item1 custom-button">1</button>
									<button on:click={on2button} class="grid-item2 custom-button">2</button>
									<button on:click={on3button} class="grid-item3 custom-button">3</button>
									<button on:click={on0button} class="grid-item-zero custom-button">0</button>
									<button on:click={onCommabutton} class="grid-item-comma custom-button">,</button>
									<button on:click={onEnter} class="grid-item-enter custom-button">Enter</button>
									<button on:click={onClear} class="grid-item-clear custom-button"
										>C <span class="tooltip">Clear</span></button
									>
								</div>
							</div>
						</div>
					</div>
					{#if InstructionComponent && showInstructions && started}
						<svelte:component
							this={InstructionComponent}
							A={paramA}
							B={paramB}
							UserInput={userinput}
							QuestionStr={questionStr}
						/>
					{:else if started && hintExists}
						<button on:click={() => showHints()} class="hint">Show hints!</button>
					{/if}
				</div>
			{/if}
		</div>
	</div>
</div>

<style>
	.keypad-content {
		display: flex;
		position: absolute;
		flex-direction: column;
	}
	.custom-button {
		background: #ffffff7c;
		border: 2px solid white;
		color: #fff;
		cursor: pointer;
		transition: box-shadow 0.2s ease;
	}

	.custom-button:hover {
		animation: glow-border 0.5s infinite alternate;
	}
	@keyframes glow-border {
		0% {
			border-color: white;
			box-shadow: 0 0 10px white;
		}
		100% {
			border-color: white;
			box-shadow: 0 0 20px white;
		}
	}

	.bttn {
		width: 100px;
		height: 30px;
		border: 2px solid rgb(255, 255, 0);
		background: none;
		color: white;
		border-radius: 5px;
		/*animation: pulse;*/
	}
	.bttn:hover {
		color: black;
		background: linear-gradient(to bottom, rgba(255, 183, 0, 0.269), yellow);
		/*transition: all 0.3s ease-in-out;*/
	}
	/* .home-button {
		width: 100px;
		height: 30px;
		border: 2px solid rgb(255, 255, 0);
		background: none;
		color: white;
		border-radius: 0;1
		animation: pulse;
	}
	.home-button:hover {
		transition: all 0.3s ease-in-out;
		border-radius: 30%;
	} */
	.forget-lesson {
		font-size: 12px;
	}
	.score-button {
		display: flex;
		flex-direction: row;
		margin-top: -50px;
		margin-left: 50px;
	}
	.keypad {
		display: flex;
		top: 5%;
		left: 0;
		right: 0;
		bottom: 0;
	}
	.content {
		display: flex;
		flex-direction: row;
		background-color: #000;
		margin-top: 50px;
	}

	.level {
		color: white;
		text-align: center;
		font-size: x-large;
		font-weight: bold;
	}

	.background {
		display: flex;
		width: 500px;
		height: 180px;
		justify-content: center;
		align-items: center;
		background-color: rgba(255, 255, 255, 0.274);
		border: 1px solid white;
	}

	.flex-box {
		color: #fff;
		font-size: 40px;
		font-weight: bolder;
		text-align: center;
	}

	.input-container {
		display: flex;
		justify-content: center;
		align-items: center;
		padding-top: 10px;
		height: 50px;
	}

	.my-input {
		width: 300px;
		height: 50px;
		background-color: #fff;
		border: 3px solid gray;
		color: #000;
		font-size: 30px;
		text-align: center;
		line-height: 50px;
	}

	.my-input:focus {
		outline: none;
		border-color: blue;
	}

	.flex-box1 {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.flex-box2 {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.task-timer {
		display: flex;
		position: center;
		font-size: 25px;
		width: 500px;
		height: 50px;
		padding: 10px;
		justify-content: space-between;
		align-items: center;
		color: #fff;
		background-color: rgba(0, 0, 0, 0.4);
	}

	button {
		width: 75px;
		height: 75px;
		margin: 5px;
		border-radius: 10px;
	}
	.container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.grid-container {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-template-rows: repeat(4, 1fr);
		font-size: 40px;
		justify-content: center;
		align-items: center;
	}
	.grid-item7 {
		grid-column: 1/2;
		grid-row: 1/2;
	}

	.grid-item8 {
		grid-column: 2/3;
		grid-row: 1/2;
	}

	.grid-item9 {
		grid-column: 3/4;
		grid-row: 1/2;
	}

	.grid-item4 {
		grid-column: 1/2;
		grid-row: 2/3;
	}

	.grid-item5 {
		grid-column: 2/3;
		grid-row: 2/3;
	}

	.grid-item6 {
		grid-column: 3/4;
		grid-row: 2/3;
	}

	.grid-item1 {
		grid-column: 1/2;
		grid-row: 3/4;
	}

	.grid-item2 {
		grid-column: 2/3;
		grid-row: 3/4;
	}

	.grid-item3 {
		grid-column: 3/4;
		grid-row: 3/4;
	}

	.grid-item-enter {
		grid-column: 4/5;
		grid-row: 3/5;
		height: 196px;
		font-size: 20px;
	}

	.grid-item-zero {
		grid-column: 1/3;
		grid-row: 4/5;
		width: 180px;
	}

	.grid-item-comma {
		grid-column: 3/4;
		grid-row: 4/5;
	}
	.grid-item-clear {
		grid-column: 4/5;
		grid-row: 2/2;
		position: relative;
		padding: 10px;
		cursor: pointer;
	}
	.grid-item-clear .tooltip {
		visibility: hidden;
		width: 60px;
		background-color: none;
		color: #fff;
		text-align: center;
		font-size: 18px;
		padding: 5px;
		position: absolute;
		bottom: 110%;
		left: 50%;
		transform: translateX(-50%);
		z-index: 1;
		transition:
			visibility 0s linear 0.5s,
			opacity 0.5s ease;
	}
	.grid-item-clear .tooltip::after {
		position: absolute;
		top: 100%;
		left: 50%;
		margin-left: -5px;
		border-width: 5px;
	}
	.grid-item-clear:hover .tooltip {
		visibility: visible;
		opacity: 1;
		transition-delay: 0.9s;
	}

	.title {
		font-size: 35px;
		margin-top: 40px;
		padding: 0;
	}
	.custom-h1 {
		padding: 0;
	}
	/* .field-text {
		display: flex;
		flex-direction: column;
		color: white;
		width: 500px;
		height: 200px;
		background-color: rgba(255, 255, 255, 0.274);
		border: 1px solid;
		justify-content: center;
		align-items: center;
	} */
	/* .component-grid-container {
		display: grid;
		grid-template-columns: 1fr 1fr;
	} */
	.info-text {
		color: white;
	}
	.correct-hint {
		display: flex;
		flex-direction: row;
		position: relative;
		width: 300px;
		height: 50px;
		background-color: red;
		border: 1px solid white;
		color: white;
		justify-content: center;
		align-items: center;
		animation: glow 1s infinite alternate;
	}
	@keyframes glow {
		0% {
			background-color: lightgreen;
			box-shadow: 0 0 5px 3px lightgreen;
		}
		100% {
			background-color: red;
			box-shadow: 0 0 10px 5px red;
		}
	}
	.progress-container {
		display: flex;
		position: absolute;
		bottom: 0;
		left: -10;
		width: 300px;
		height: 5px;
		margin-top: 5px;
		border-radius: 0 5px 5px 0;
		overflow: hidden;
	}
	.progress-bar {
		width: 0%; /* Start width, maybe not needed*/
		height: 5px;
		background-color: white;
		border-radius: 0 5px 5px 0;
		transition: width 3s ease;
	}

	.solution {
		font-size: 20px;
	}
	.hint {
		background: none;
		border: 2.5px solid yellow;
		color: #fff;
		width: 70px;
		height: 60px;
		animation: pulse 1s infinite;
		margin-top: 220px;
	}
	.to-do {
		display: flex;
		flex-direction: row;
		font-size: 10px;
		color: white;
		height: 80px;
		padding: 10px;
		left: 10px;
		z-index: 1;
	}

	.task {
		display: flex;
		flex-direction: row;
	}

	/* .box::before {
		content: attr(data-number);
		position: absolute;
		bottom: 100%;
		right: calc(100% - 15px);
		padding: 2px;
		border-radius: 2px;
		font-size: 8px;
	} */
	.task-left {
		display: flex;
		flex-direction: row;
	}
	.stair-keypad {
		display: flex;
		flex-direction: column;
	}
	.repeat-available {
		display: flex;
		width: 100px;
		flex-direction: column;
		z-index: 1;
	}
	.repeat-time{
		font-size: 10px;
		width: 50px;
	}
	.time{
		display: flex;
		flex-direction: row;
		gap: 40px;
	}
	.available {
		margin-top: -20px;
		color: gold;
	}
	.small-font{
		font-size: 10px;
	}
</style>

