<script lang="ts">
    import { fetchGet, fetchPost } from "../services/fetch";

    export let base64Image="";
    export let onClick: (x: number, y: number, left: number, right: number, top: number, bottom: number) => void = () => {};
    export let hint1="";
    export let hint2="";
    export let hint3="";
   // export let hintsShown = 0;

    let alreadyClicked = false;
    let imageRef: HTMLImageElement;



    $: if (base64Image || hint1 || hint2 || hint3) {
        base64Image = prependImgType(base64Image);
        hint1 = prependImgType(hint1);
        hint2 = prependImgType(hint2);
        hint3 = prependImgType(hint3);
    }

    function prependImgType(image: string) {

        if (image.length && !image.startsWith("data:image/")) {
            if (image.startsWith("/9j/")) {
                image = `data:image/jpeg;base64,${image}`;
            } else {
                image = `data:image/png;base64,${image}`;
            }
        }
        return image;
    }

   

    function handleClick(event: MouseEvent) {
        console.log("click image");

        alreadyClicked = true;
        const rect = imageRef.getBoundingClientRect();
        console.log(rect);

        let x = Math.floor(event.clientX);
        let y = Math.floor(event.clientY);
        let left = Math.floor(rect.left);
        let right = Math.floor(rect.right);
        let top = Math.floor(rect.top);
        let bottom = Math.floor(rect.bottom);

        // Example to calculate normalizedX
        const naturalWidth = imageRef.naturalWidth;
        const scaledWidth = rect.width;
        const scaleRatio = scaledWidth / naturalWidth;
        const actualWidth = scaleRatio >= 1 ? naturalWidth : scaledWidth / scaleRatio;
        const normalizedX = (event.clientX - rect.left) / actualWidth;

        console.log({ eventClientX: event.clientX, rectLeft: rect.left, normalizedX });
        onClick(x, y, left, right, top, bottom);
    }
</script>


<div class="thumbnail">
    <div class="preview">
            {#if base64Image}
                <img bind:this={imageRef} on:click={handleClick} alt="icon" src={base64Image} />
            {/if}

            {#if hint1}
                <img src={hint1} alt="hint"/>
            {/if}
            {#if hint2}
                <img src={hint2} alt="hint"/>
            {/if}
            {#if hint3}
                <img src={hint3} alt="hint"/>
            {/if}
        <div class="applied-rating">Rating</div>
       
    </div>
</div>

<style>
</style>
