<script lang="ts">
  //App.svelte
  import Login from "./Login.svelte";
  import { Router, Route, Link, navigate } from "svelte-routing";
  import Signup from "./Signup.svelte";
  import Landing from "./Landing.svelte";
  import Keypad from "./Keypad/Keypad.svelte";
  import Keypadmem from "./Keypadmem/Keypadmem.svelte";
  import Home from "./Home.svelte";
  import { SvelteToast } from "@zerodevx/svelte-toast";
  import PrivateRoute from "./PrivateRoute.svelte";
  import Menu from "./Menu.svelte";
  import Profile from "./Profile.svelte";
  import Achievement from "./Achievements.svelte";
  import Stat from "./Statistics.svelte";
  import Strike from "./Strike.svelte";
  import ConnectionAlert from "./ConnectionAlert.svelte";
  import Admin from "./Admin.svelte";
  import { isMobile, isLoading, newEvent } from "./services/stores";
  import FibonacciSpinner from "./loader/FibonacciSpinner.svelte";
  import Demo from "./Demo.svelte";
  import Appliedcreate from "./Appliedmath/AppliedCreate.svelte";
  import Appliedsolve from "./Appliedmath/Appliedmathsolve.svelte";
  import AppliedSelection from "./Appliedmath/AppliedSelection.svelte";
  import Accomplished from "./Accomplished.svelte";
  import { fetchGet, fetchPost } from "./services/fetch";
  import Problemlist from "./Problemlist.svelte";
  import Charid from "./Charid.svelte";
  import KeypadEq from "./Keypadmem/KeypadEq.svelte";
  import Sounds from './Sounds.svelte';
  const options = {};

  // This code could be placed in main.ts or in an initial loading component
  document.addEventListener("DOMContentLoaded", () => {
    isMobile.set(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
  });

  async function AcknowledgeAccomplishment() {
    var url = "AppliedMath/AcknowledgeAccomplishment";
    await fetchPost<void, string>(url, $newEvent);
    newEvent.set("");
  }
</script>

<SvelteToast {options} />

<div class="container-flex">
  <Menu hamburger={true}></Menu>
</div>

{#if $newEvent}
  <div id="popup-overlay" class="hidden">
    <div id="popup">
      <!-- <h1>New event: {$newEvent}</h1>  -->
      <Accomplished></Accomplished>
      <button on:click={AcknowledgeAccomplishment} id="close-button">Continue</button>
    </div>
  </div>
{/if}

<ConnectionAlert></ConnectionAlert>

<div class="overlay" style="display: {$isLoading ? 'block' : 'none'};">
  <div class="overlay-canva" style="width: 300px; height: 300px; margin: 0; z-index: 1000;">
    <FibonacciSpinner canvasid="fibId"></FibonacciSpinner>
  </div>
</div>

<Profile></Profile>

<Router on:routeLoaded={() => alert("routeLoaded")}>
  <main>
    <Route path="/login" component={Login} />
    <Route path="/login/:slug" component={Login} />
    <Route path="/signup" component={Signup} />
    <Route path="/landing" component={Landing} />
    <Route path="/demo" component={Demo} />
    <Route path="/settings" component={Sounds} />
    <!-- <Route path="/" component={Home} /> -->
    <PrivateRoute path="/" component={Home} />

    <Route path="/achievement" component={Achievement} />
    <Route path="/statistic" component={Stat} />
    <Route path="/Strike" component={Strike} />

    <PrivateRoute path="/charid" component={Charid} />
    <PrivateRoute path="/problemlist" component={Problemlist} />

    <PrivateRoute path="/createapplied" component={Appliedcreate} />
    <PrivateRoute path="/applied/:charid/edit" component={Appliedcreate} />

    <PrivateRoute path="/appliedselection" component={AppliedSelection} />
    <PrivateRoute path="/appliedmath" component={Appliedsolve} />
    <PrivateRoute path="/applied/:slug" component={Appliedsolve} />

    <PrivateRoute path="/keypadeq" component={KeypadEq} />
    <PrivateRoute path="/keypadeq/:slug" component={KeypadEq} />
    <!-- <PrivateRoute path="/appliedmath/:slug" component={Appliedsolve} /> -->
    <PrivateRoute path="/home" component={Home} />
    <PrivateRoute path="/mental/:slug" component={Keypad} />
    <PrivateRoute path="/keypadmem/:slug" component={Keypadmem} />
    <PrivateRoute path="/admin" component={Admin} />
  </main>
</Router>

<style>
  :global(.error) {
    --toastBackground: red;
    --toastBarBackground: white;
    --toastWidth: 400px;
  }
  :global(.success) {
    --toastBackground: #008800;
    --toastBarBackground: #1010aa;
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 1500px;
    height: 1500px;
    background-color: rgba(0, 0, 0, 0.779);
    z-index: 999;
    justify-content: center;
    align-items: center;
  }
</style>
