<script lang="ts">
    export let achievedNewProgress = false;

</script>

{#if achievedNewProgress}

    <div class="overlay" id="overlay">
        <h1>New game unlocked!</h1>
        <img alt="home" src="/unlocked.svg" class="glow-image" width="150px" height="150px" />
        <h2>Click the home button to see it</h2>
        <button on:click={ ()=>achievedNewProgress=false }    data-test="close-button" class="close-button" id="close-button">Close</button>
    </div>
{/if}

<style>
    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.8);
        z-index: 9999;
    }

    h2 {
        text-align: center;
        margin: 50px 0 0 0;
    }
    #close-button {
        cursor: pointer;
        border: none;
        position: relative;
        width: 100px;
        color: black;
    }

    #close-button:hover {
        visibility: visible;
        color: black;
        background: linear-gradient(to bottom, rgba(255, 183, 0, 0.269), yellow);
    }
    .glow-image {
        animation: glow 1s infinite alternate;
        border: none;
        border-radius: 50%;
    }

    @keyframes glow {
        0% {
            box-shadow: 0 0 10px 10px gold; 
        }
        50% {
            box-shadow: 0 0 20px 15px rgb(255, 255, 0); 
        }
        100% {
            box-shadow: 0 0 30px 20px rgb(255, 255, 255); 
        }
    }
</style>
