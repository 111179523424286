<!-- Signup.Svelte -->
<script lang="ts">
	import { navigate, Link } from "svelte-routing";
	import { UserService } from "./services/user.service";
	import { Hash } from "./services/hash2";
	import { authHeader } from "./services/auth-header";
	import { Errorhandler } from "./services/Errorhandler";
	import { toast } from "@zerodevx/svelte-toast";
	//import Cookie from "./Cookie.svelte";
	import { isAuthenticated, userName } from "./services/stores";

	$: nameErrorMessage = nameError(user.name);
	$: emailErrorMessage = emailError(user.email);
	$: passwordErrorMessage = passwordError(user.password);
	$: allowRegister = !nameErrorMessage && !passwordErrorMessage && !emailErrorMessage;
	$: status_loggingIn = false;
	$: status_registering = false;
	$: passwordFieldType = showPassword ? "text" : "password";

	let allowCookies = true;

	function handlePasswordInput(event: Event) {
		const input = event.target as HTMLInputElement;
		if (input) {
			user.password = input.value;
		}
	}

	let apiUrl = import.meta.env.VITE_API;

	class User {
		public name: string = "";
		public password: string = "";
		public email: string = "";
		constructor(user: User | undefined) {
			if (user) {
				this.name = user.name;
				this.password = user.password;
				this.email = user.email;
			}
		}
	}

	//let linkUrl = "/signup";
	let user: User = new User(undefined);
	let submitted: boolean = false;
	let serverMessage: string = "";
	let userService = new UserService();
	let isRegister = true; //register/update

	//	async function handleSubmit() {
	async function signup(e: Event) {
		e.preventDefault(); // Stop the page from reloading

		submitted = true;
		status_registering = true;
		serverMessage = "";
		var encryptedUser = new User(user);
		encryptedUser.name = user.name.trim();
		var hash = new Hash();
		encryptedUser.password = hash.encrypt(user.password);
		var url = apiUrl + "/Login/Update";
		console.log("url " + url);

		if (isRegister) {
			url = apiUrl + "/Login/Register";
		}

		console.log("Call the server");

		try {
			const response = await fetch(url, {
				method: "post",
				headers: {
					...authHeader(),
					Accept: "application/json",
					"Content-Type": "application/json",
				},
				body: JSON.stringify(encryptedUser),
			});

			const data = await response.json();
			status_registering = false; //spinner

			if (Errorhandler.HandleError(data)) {
				return;
			}

			console.log("Registration success");
			toast.push("Registration success", {
				classes: ["success"],
				pausable: true,
			});

			const loggedinMessage = await userService.login(user.name, user.password);
			console.log("go to /");
			toast.push("Logged in", {
				classes: ["success"],
				pausable: true,
			});
			console.log("Logged in");
			isAuthenticated.set(true);
			userName.set(user.name);

			// window.location.reload(); // Losing our debug state here
			navigate("/");
		} catch (error) {
			console.error("An error occurred:", error);
			// Handle the error appropriately
			// For example, show an error message to the user
			toast.push("Registration error " + error, {
				classes: ["error"],
				initial: 0,
				dismissable: true, // Adds a close button
			});
		}
	}

	let showPassword = false;

	function togglePassword() {
		showPassword = !showPassword;
	}

	function nameError(userName: string): string {
		if (userName.trim().length < 3) {
			return "Display name must be minimum 3 characters";
		}

		if (userName.search("  ") >= 0) return "Display name can not have 2 blank characters in a row";
		return "";
	}

	function emailError(userEmail: string): string {
		if (userEmail.length <= 0) {
			return "Missing email";
		}
		if (userEmail.indexOf("@") < 0) {
			return "Invalid email";
		}
		return "";
	}

	function passwordError(userPassword: string): string {
		if (userPassword.length <= 0) {
			return "Missing password";
		}
		if (userPassword.length < 5) {
			return "Password must have minimum 5 characters";
		}
		return "";
	}
</script>

<!-- <button on:click={signup}> Button on the sign up page </button> -->

<div class="button-container">
	<!-- <button
		on:click={() => navigate("/landing")}
		data-test="close-button"
		class="close-button"
	>
		<img
			src="https://d35aaqx5ub95lt.cloudfront.net/images/ed25a8cf69261b0c1e25b147f369f74a.svg"
			alt="Close button"
		/>
	</button> -->
	<form class="log-in">
		<div class="signup">
			<h2>Create Account</h2>
			<div class="info">
				<div class="name-id">
					<div class="name">
						<input
							data-test="full-name-input"
							autocomplete="name"
							placeholder="Username"
							class="name-input"
							type="username"
							bind:value={user.name}
						/>
					</div>
				</div>
				{#if nameErrorMessage}<div class="invalid-feedback">
						{nameErrorMessage}
					</div>{/if}
				<div class="username">
					<input
						data-test="email-input"
						autocomplete="email"
						placeholder="Email"
						class="email-username"
						type="email"
						bind:value={user.email}
					/>
				</div>
				{#if emailErrorMessage}<div class="invalid-feedback">
						{emailErrorMessage}
					</div>{/if}
				<div class="enter-pass">
					<div class="password">
						<input
							data-test="password-input"
							autocomplete="current-password"
							placeholder="Enter password"
							class="enterpass"
							type={passwordFieldType}
							on:input={handlePasswordInput}
						/>
						<button on:click={togglePassword} class="eye-button" tabindex="-1" type="button">
							{#if showPassword}
								<img
									alt="eye-open"
									class="_3IRjC _1fHYG"
									src="https://d35aaqx5ub95lt.cloudfront.net/vendor/39eca93012fa2f5fb6513b775a2eded8.svg"
								/>
							{:else}
								<img
									alt="eye-close"
									class="_3IRjC _1fHYG"
									src="https://d35aaqx5ub95lt.cloudfront.net/vendor/4c908333e9ced51ed0c62c41393fe9fb.svg"
								/>
							{/if}
						</button>
					</div>
				</div>
				{#if passwordErrorMessage}<div class="invalid-feedback">
						{passwordErrorMessage}
					</div>{/if}
			</div>
			<p>
				<input type="checkbox" bind:checked={allowCookies} />I agree to the Privacy Policy and the Cookies Policy to improve the
				user experience.
			</p>
		</div>
		{#if allowRegister && allowCookies}
			<button on:click={signup} class="login-button" data-test="register-button" type="submit" style="color: black;">SIGN UP</button>
			{#if status_registering}
				<!-- <img alt="see password"
					src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
				/> -->
			{/if}
		{/if}
		<div class="account" style="color: white;">
			Already have an account? <Link to="/login">Log In</Link>
		</div>
	</form>
	<!-- <Cookie></Cookie> -->
</div>

<style>
	.invalid-feedback {
		color: rgb(111, 111, 111);
		font-size: 12px;
		font-weight: 1px;
		margin: 1px;
	}
</style>
