<!-- Keypadmem.svelte -->

<script lang="ts">
	//We might not have hints for the memory part. Maybe just show a table or list with numbers
	//Instead of showing "hint button", we show progress button
	//We could have to modes Learn and Test. For the test we run the mentalmath type of game, with max 3 errors
	//Maybe we could also start in "Test mode" and progress to "Learn mode" If we make mistakes.

	//For the memory game, load just one question
	//Send answer back to the server
	import { navigate } from "svelte-routing";
	import { tick } from "svelte";
	import { toast } from "@zerodevx/svelte-toast";
	import { onMount, onDestroy } from "svelte";
	import { writable } from "svelte/store";
	import { fetchGet, fetchPost } from "../services/fetch";
	import type { SvelteComponent } from "svelte";
	import { type ResultInterface } from "./ResultInterface";
	import "../Funnel.css";
	import { isMobile } from "../services/stores";
	//import { parse, simplify } from "mathjs";
	import type { MathNode, OperatorNode, FunctionNode, ParenthesisNode, SymbolNode, ConstantNode } from "mathjs";

	import { mathContent } from "../services/stores";
	import { userinput } from "../services/stores";
	import { calcEnter } from "../services/stores";
	import NewGame from "../GameUnlocked.svelte";

	export let slug: any;

	//Some instruction-components need parameters
	//let paramNo = 0;
	let InstructionComponent: typeof SvelteComponent | null = null;
	//let showInstructions = false;
	let showInstructions = true;
	let isGameOver = false;

	let isShowHighScore = false;
	let isShowHighscoreButton = true;
	let showQuestionNotSolution = true;
	let infoText = "";
	let paramA: string = "-1";
	let paramB: string = "-1";
	let tasksLeft = 0;
	let comingUp = 0;
	let hintExists = false;
	let convertToLatexError = false;

	// async function loadMath() {
	// 	const { parse, simplify } = await import("mathjs");
	// 	// Now you can use parse and simplify as needed
	// }

	// Declare variables to store the Math.js functions globally within your script
	let parse: any;
	let simplify: any;

	async function loadMath() {
		const math = await import("mathjs");
		parse = math.parse;
		simplify = math.simplify;	
	}

	//await loadMath(); only top levfel

	loadMath().then(() => {
		// Now you can be sure that mathjs is loaded, and you can use parse and simplify globally
		console.log("Math.js has been loaded.");
	});

	// remove temporary to open from url instead of with lesson
	$: handleRouteChange(slug);

	$: if (started) {
		console.log("started " + started);
	}
	$: if (showInstructions) {
		console.log("showInstructions " + showInstructions);
	}
	$: if (isShowHighScore) {
		console.log("isShowHighscore: " + isShowHighScore);
	}
	$: if (isShowHighscoreButton) {
		console.log(`showHighscoreButton: ${isShowHighscoreButton}`);
	} else {
		console.log(`showHighscoreButton: ${isShowHighscoreButton}`);
	}

	$: console.log(`showHighscoreButton2: ${isShowHighscoreButton}`);

	$: console.log(`InstructionComponent?=${InstructionComponent ? true : false} showInstructions=${showInstructions} started=${started}`);

	let notRemembered: number = 0;
	$: console.log("notRemembered 2" + notRemembered);

	let secondsRemembered: number = 0;
	$: console.log("secondsRemembered: " + secondsRemembered);

	$: console.log(currentTask);

	let finished = false; //More questions
	$: console.log("finished: :" + finished);

	$: if (started && showQuestionNotSolution && inputElement) focusInput();

	$: console.log("hints exist :" + hintExists);

	$: if (questionStr) {
		console.log("KeypadEq $: if (questionStr) new questionstring: " + questionStr);
		mathQuestion.set(questionStr);
		paramA = questionStr;

		updateMathJax();
	}

	$: if ($userinput) {
		processUserInput($userinput);
	}

	$: if ($calcEnter) {
		$calcEnter = false;

		onEnter();
	}

	// Type guard to check if a node is an OperatorNode
	function isOperatorNode(node: math.MathNode): node is math.OperatorNode {
		return node.type === "OperatorNode";
	}

	// Type guard to check if a node is a FunctionNode
	function isFunctionNode(node: math.MathNode): node is math.FunctionNode {
		return node.type === "FunctionNode";
	}

	// Type guard to check if a node is a ParenthesisNode
	function isParenthesisNode(node: math.MathNode): node is math.ParenthesisNode {
		return node.type === "ParenthesisNode";
	}

	// Function to canonicalize expressions by sorting operands of commutative operators
	function canonicalize(node: math.MathNode): math.MathNode {
		if (isOperatorNode(node)) {
			const operatorNode = node;
			// Recursively canonicalize the arguments
			operatorNode.args = operatorNode.args.map(canonicalize);

			// Check if the operator is commutative
			if (isCommutative(operatorNode)) {
				// Sort the arguments
				operatorNode.args.sort(compareNodes);
			}
		} else if (isFunctionNode(node)) {
			const functionNode = node;
			// Recursively canonicalize the arguments
			functionNode.args = functionNode.args.map(canonicalize);
		} else if (isParenthesisNode(node)) {
			const parenthesisNode = node;
			// Canonicalize the content inside parentheses
			parenthesisNode.content = canonicalize(parenthesisNode.content);
		}
		// For other node types (ConstantNode, SymbolNode, etc.), no action is needed
		return node;
	}

	// Function to determine if an operator is commutative
	function isCommutative(node: math.OperatorNode): boolean {
		return node.op === "+" || node.op === "*";
	}

	// Function to compare two nodes for sorting
	function compareNodes(a: math.MathNode, b: math.MathNode): number {
		const aKey = nodeKey(a);
		const bKey = nodeKey(b);
		if (aKey < bKey) return -1;
		if (aKey > bKey) return 1;
		return 0;
	}

	// Function to generate a unique key for each node for comparison
	function nodeKey(node: math.MathNode): string {
		if (node.type === "SymbolNode") {
			const symbolNode = node as math.SymbolNode;
			return "Symbol:" + symbolNode.name;
		} else if (node.type === "ConstantNode") {
			const constantNode = node as math.ConstantNode;
			return "Constant:" + constantNode.value;
		} else if (node.type === "OperatorNode") {
			const operatorNode = node as math.OperatorNode;
			const argsKeys = operatorNode.args.map(nodeKey).sort().join(",");
			return "Operator:" + operatorNode.op + ":" + argsKeys;
		} else if (node.type === "FunctionNode") {
			const functionNode = node as math.FunctionNode;
			const argsKeys = functionNode.args.map(nodeKey).sort().join(",");
			return "Function:" + functionNode.fn.name + ":" + argsKeys;
		} else if (node.type === "ParenthesisNode") {
			const parenthesisNode = node as math.ParenthesisNode;
			return "Parenthesis:" + nodeKey(parenthesisNode.content);
		} else {
			// For other node types, use their string representation
			return node.type + ":" + node.toString();
		}
	}

	function processUserInput(input: string) {
		try {
			let mathUser = simplifyExpression(input);
			let mathUserStr = mathUser.toString();

			let latex = convertToLatex(mathUserStr);
			if (latex != "error") {
				mathView.set(latex);
				convertToLatexError = false;

				///await tick(); // Wait for DOM update //TODO Not necessary to do twice
				updateMathJax();
			} else {
				convertToLatexError = true;
			}

			console.log(input);
		} catch (error) {
			console.error(error);
		}
	}

	function convertToLatex(input: string) {
		try {
			// Parse the input into an AST (Abstract Syntax Tree)
			const node = parse(input);

			// Generate LaTeX from the AST
			let latex = node.toTex();

			console.log("latex: ", latex);

			// Optionally wrap with delimiters for MathJax
			return `$$${latex}$$`;
		} catch (error) {
			console.error("Error parsing input:", error);
			//return "Waiting for legal input";
			return "error";
		}
	}

	async function updateMathJax() {
		await tick();

		if (typeof window !== "undefined" && window.MathJax) {
			await window.MathJax.typesetPromise();
		}

		// if (window.MathJax) {
		// 	await window.MathJax.typesetPromise(); //TODO not necessary to do twice
		// }
	}

	//we should only load it once, but we should set the params later
	//loadHints();

	//mathjax
	let mathQuestion = writable("");
	let mathView = writable("");

	let remembered1Minute: number = 0;
	let remembered2Minute: number = 0;
	let remembered5Minute: number = 0;
	let remembered10Minute: number = 0;
	let remembered30Minute: number = 0;
	let remembered1hour: number = 0;
	let remembered1day: number = 0;
	let remembered1week: number = 0;
	let remembered1month: number = 0;
	let inLongTermMemory: number = 0;

	let repeatNext1min: number = 0;
	let repeatNext2min: number = 0;
	let repeatNext5min: number = 0;
	let repeatNext10min: number = 0;
	let repeatNext30min: number = 0;
	let repeatNext1H: number = 0;
	let repeatNext1D: number = 0;
	let repeatNext1W: number = 0;
	let repeatNext1M: number = 0;

	let achievedNewProgress = false;
	let showBackground = false;

	async function handleRouteChange(slug: string) {
		focusInput();
		await setCurrentLesson(slug);
		loadHints();
	}

	interface Translation {
		id: number;
		question: string;
		answer: string;
		points: number;
		available_questions: number;

		remembered1Minute: number;
		remembered2Minute: number;
		remembered5Minute: number;
		remembered10Minute: number;
		remembered30Minute: number;
		remembered1hour: number;
		remembered1day: number;
		remembered1week: number;
		remembered1month: number;
		inLongTermMemory: number;
		repeatNext1min: number;
		repeatNext2min: number;
		repeatNext5min: number;
		repeatNext10min: number;
		repeatNext30min: number;
		repeatNext1H: number;
		repeatNext1D: number;
		repeatNext1W: number;
		repeatNext1M: number;

		achievedNewProgress: boolean;
		tasksLeft: number;
	}
	const showDataNumber = remembered1month > 0;

	interface LessonVM {
		//public long Id { get; set; }
		name: string;
		title: string;
		description: string;
		// [Column("lessontype")]
		// public LessonTypeEnum LessonType { get; set; }

		// [Column("level")]
		// public int Level { get; internal set; }

		// [Column("goldtime")] //Time in ms to
		// public double GoldTime { get; set; }

		// [Column("silvertime")]
		// public double SilverTime { get; set; }

		// [Column("bronzetime")]
		// public double BronzeTime { get; set; }
	}

	let questionStr = "";

	let started = false;
	let currentTask: Translation;
	let lessonDescription = "Loading";
	let title = "";
	let inputElement: HTMLInputElement | null = null;

	//using the mouse/fingers on the screen elements
	function on1button() {
		$userinput += "1";
		//$userinput.update(value => value + '1');
	}
	function on2button() {
		userinput.update((value) => value + "2");
	}
	function on3button() {
		userinput.update((value) => value + "3");
	}
	function on4button() {
		userinput.update((value) => value + "4");
	}
	function on5button() {
		userinput.update((value) => value + "5");
	}
	function on6button() {
		userinput.update((value) => value + "6");
	}
	function on7button() {
		userinput.update((value) => value + "7");
	}
	function on8button() {
		userinput.update((value) => value + "8");
	}
	function on9button() {
		userinput.update((value) => value + "9");
	}
	function on0button() {
		userinput.update((value) => value + "0");
	}
	function onCommabutton() {
		userinput.update((value) => value + ".");
	}
	function onClear(event: MouseEvent & { currentTarget: EventTarget & HTMLButtonElement }) {
		if ($userinput.length > 0) {
			$userinput = $userinput.substring(0, $userinput.length - 1);
		}
	}
	function onopenParenthesis() {
		$userinput += "(";
	}
	function oncloseParenthesis() {
		$userinput += ")";
	}
	function onXy() {
		$userinput += "^";
	}
	function onSqrt() {
		$userinput += "√";
	}
	function onX() {
		$userinput += "x";
	}
	function onNumber() {
		$userinput += "n";
	}
	function onMultiply() {
		$userinput += "×";
	}
	function onAdd() {
		$userinput += "+";
	}
	function onSubtract() {
		$userinput += "-";
	}
	function onDivide() {
		$userinput += "/";
	}
	function onSin() {
		$userinput += "sin(x)";
	}
	function onCos() {
		$userinput += "cos(x)";
	}
	function onE() {
		$userinput += "e^x";
	}
	function onLn() {
		$userinput += "ln(x)";
	}
	function onA() {
		$userinput += "a";
	}
	//Would be better that this is active only if this component is visible
	function onEnter() {
		$userinput = $userinput.replaceAll(",", ".");

		if ($userinput.length > 0) {
			console.log();
			if ($userinput.charAt(0) == "." || $userinput.charAt(0) == ",") {
				$userinput = "0" + $userinput;
			}
		}

		if (started) {
			if ($userinput == "") return;
			CheckAnswer();
		} else {
			StartGame();
		}
		showBackground = true;
	}

	async function loadHints() {
		hintExists = false;
		try {
			//const fileName = `./instructions/${slug}Instructions.svelte`
			// 	.replaceAll("-", "_")
			// 	.replaceAll("×", "_times_");
			// console.log("Component name: " + fileName);

			//Wow, rename in the explorer renames here! Mind blown
			const componentMap: any = {
				//small: () => import("../instructions/mental/01_Small.svelte"),
				derivatives: () => import("../instructions/memory/Derivatives.svelte"),
				integrals: () => import("../instructions/memory/Integrals.svelte"),
			};

			// if (componentMap[slug]) {
			// 	console.error("No entry for slug:", slug);
			// 	throw "No entry for slug";
			// }

			if (componentMap[slug]) {
				let module1 = componentMap[slug];
				console.log("Importing component module:", module1); // Log the imported module

				const module = await componentMap[slug]();
				console.log("Imported component module:", module); // Log the imported module
				InstructionComponent = module.default;
				console.log("InstructionComponent set to:", InstructionComponent); // Log the component itself
				hintExists = true;
			} else {
				console.error("Component not found for slug:", slug);
			}
		} catch (error) {
			console.error("Error loading component:", error);
		}
	}

	async function NextTask() {
		var clientUrl = "/Crammer/NextQuestion/";
		currentTask = await fetchGet<Translation>(clientUrl);
		showCurrentTask();
	}

	async function StartGame() {
		NextTask();

		started = true;

		setTimeout(() => {
			focusInput();
		}, 0);
	}
	function tokenize(expr: string): string[] {
		const tokens: string[] = [];
		const functions = ["sin", "cos", "tan", "ln", "log", "exp", "acos", "asin", "atan", "arcsin", "arccos", "arctan"];
		let i = 0;

		while (i < expr.length) {
			const char = expr[i];

			if (/\d/.test(char)) {
				// Number
				let num = char;
				i++;
				while (i < expr.length && /[\d\.]/.test(expr[i])) {
					num += expr[i];
					i++;
				}
				tokens.push(num);
			} else if (/[a-zA-Z]/.test(char)) {
				// Check for function names
				let foundFunction = false;
				for (let func of functions) {
					const funcLength = func.length;
					const substring = expr.substring(i, i + funcLength);
					if (substring === func) {
						tokens.push(func);
						i += funcLength;
						foundFunction = true;
						break;
					}
				}
				if (!foundFunction) {
					// Treat individual letters as variables
					tokens.push(char);
					i++;
				}
			} else if (char === "(" || char === ")") {
				tokens.push(char);
				i++;
			} else if (/\s/.test(char)) {
				// Skip whitespace
				i++;
			} else {
				// Operator or other character
				tokens.push(char);
				i++;
			}
		}
		return tokens;
	}

	function isOperand(token: string, functions: string[]): boolean {
		if (/\d+(\.\d+)?/.test(token)) {
			// Number
			return true;
		} else if (/[a-zA-Z]+/.test(token)) {
			// Variable (exclude functions)
			return !functions.includes(token);
		} else {
			return false;
		}
	}

	function isFunction(token: string, functions: string[]): boolean {
		return functions.includes(token);
	}

	function insertImplicitMultiplication(expr: string): string {
		const functions = ["sin", "cos", "tan", "ln", "log", "exp", "acos", "asin", "atan", "arcsin", "arccos", "arctan"];
		const tokens = tokenize(expr);
		const outputTokens: string[] = [];

		for (let i = 0; i < tokens.length; i++) {
			const currentToken = tokens[i];
			outputTokens.push(currentToken);

			if (i < tokens.length - 1) {
				const nextToken = tokens[i + 1];

				const currentIsOperand = isOperand(currentToken, functions);
				const nextIsOperand = isOperand(nextToken, functions);

				const needMultiplication =
					(currentIsOperand && nextIsOperand) ||
					(currentIsOperand && nextToken === "(") ||
					(currentToken === ")" && (nextIsOperand || isFunction(nextToken, functions) || nextToken === "("));

				if (needMultiplication) {
					outputTokens.push("*");
				}
			}
		}

		return outputTokens.join("");
	}

	function simplifiedEqual(userExpression: string, expectedExpression: string): boolean {
		// userExpression = userExpression.replace(/×/g, "*");
		// expectedExpression = expectedExpression.replace(/×/g, "*");

		// // Insert implicit multiplication where necessary
		// userExpression = insertImplicitMultiplication(userExpression);
		// expectedExpression = insertImplicitMultiplication(expectedExpression);

		// const parsedExpectedExpr = math.parse(expectedExpression);
		// const parsedUserExpr = math.parse(userExpression);

		// const simplifiedExpectedExpr = math.simplify(parsedExpectedExpr);
		// const simplifiedUserExpr = math.simplify(parsedUserExpr);

		// // Log expressions for debugging
		// console.log("Simplified User Expression:", simplifiedUserExpr.toString());
		// console.log("Simplified Expected Expression:", simplifiedExpectedExpr.toString());

		// // Canonicalize the simplified expressions
		// const canonicalExpectedExpr = canonicalize(simplifiedExpectedExpr);
		// const canonicalUserExpr = canonicalize(simplifiedUserExpr);

		// // Log expressions for debugging
		// console.log("Canonicalized User Expression:", canonicalUserExpr.toString());
		// console.log("Canonicalized Expected Expression:", canonicalExpectedExpr.toString());
		let canonicalUserExpr = simplifyExpression(userExpression);
		let canonicalExpectedExpr = simplifyExpression(expectedExpression);

		const isEquivalent = canonicalExpectedExpr.equals(canonicalUserExpr);
		return isEquivalent;
	}

	function simplifyExpression(userExpression: string): MathNode {
		userExpression = userExpression.replace(/×/g, "*");
		userExpression = insertImplicitMultiplication(userExpression);
		const parsedUserExpr = parse(userExpression);
		const simplifiedUserExpr = simplify(parsedUserExpr);
		return canonicalize(simplifiedUserExpr);
	}

	// function simplify(userExpression: string): math.MathNode {
	// 	userExpression = userExpression.replace(/×/g, "*");
	// 	//expectedExpression = expectedExpression.replace(/×/g, "*");

	// 	// Insert implicit multiplication where necessary
	// 	userExpression = insertImplicitMultiplication(userExpression);
	// 	//expectedExpression = insertImplicitMultiplication(expectedExpression);

	// 	//const parsedExpectedExpr = math.parse(expectedExpression);
	// 	const parsedUserExpr = parse(userExpression);

	// 	//const simplifiedExpectedExpr = math.simplify(parsedExpectedExpr);
	// 	const simplifiedUserExpr = simplify(parsedUserExpr);

	// 	// Log expressions for debugging
	// 	//console.log("Simplified User Expression:", simplifiedUserExpr.toString());
	// 	//console.log("Simplified Expected Expression:", simplifiedExpectedExpr.toString());

	// 	// Canonicalize the simplified expressions
	// 	//const canonicalExpectedExpr = canonicalize(simplifiedExpectedExpr);
	// 	const canonicalUserExpr = canonicalize(simplifiedUserExpr);

	// 	// Log expressions for debugging
	// 	//console.log("Canonicalized User Expression:", canonicalUserExpr.toString());
	// 	//console.log("Canonicalized Expected Expression:", canonicalExpectedExpr.toString());

	// 	//const isEquivalent = canonicalExpectedExpr.equals(canonicalUserExpr);
	// 	//return isEquivalent;

	// 	return canonicalUserExpr;
	// }

	async function CheckAnswer() {
		let IsCorrect = false;

		if (currentTask) {
			if ($userinput == currentTask.answer || simplifiedEqual($userinput, currentTask.answer)) {
				toast.push("Correct " + currentTask.answer, {});

				await ItsCorrect(currentTask.id);
				IsCorrect = true;
			} else {
				const toastId = toast.push("Should be " + currentTask.answer + " for " + currentTask.question, {
					classes: ["error"],
					initial: 0,
					dismissable: false, // Adds a close button
				});
				setTimeout(() => {
					toast.pop(toastId);
				}, 3000);
				await ItsWrong(currentTask.id);
			}

			$userinput = "";
			mathView.set(""); //should have been done automatically by $: if($userinput)
			updateMathJax();
		}
	}

	//sets currentTask from lessonData
	function showCurrentTask() {
		console.log("**showCurrentTask");
		//showInstructions = false;

		questionStr = currentTask.question;

		if (questionStr) {
			const splitArray = questionStr.split("\n");
			questionStr = splitArray[0];
			if (splitArray.length == 2) infoText = splitArray[1];
		} else {
			questionStr = "";
			finished = true;
			started = false;
		}

		notRemembered = currentTask.available_questions;
		secondsRemembered = currentTask.points;

		repeatNext1min = currentTask.repeatNext1min;
		repeatNext2min = currentTask.repeatNext2min;
		repeatNext5min = currentTask.repeatNext5min;
		repeatNext10min = currentTask.repeatNext10min;
		repeatNext30min = currentTask.repeatNext30min;
		repeatNext1H = currentTask.repeatNext1H;
		repeatNext1D = currentTask.repeatNext1D;
		repeatNext1W = currentTask.repeatNext1W;
		repeatNext1M = currentTask.repeatNext1M;
		remembered1Minute = currentTask.remembered1Minute;
		remembered2Minute = currentTask.remembered2Minute;
		remembered5Minute = currentTask.remembered5Minute;
		remembered10Minute = currentTask.remembered10Minute;
		remembered30Minute = currentTask.remembered30Minute;
		remembered1hour = currentTask.remembered1hour;
		remembered1day = currentTask.remembered1day;
		remembered1week = currentTask.remembered1week;
		remembered1month = currentTask.remembered1month;

		inLongTermMemory = currentTask.inLongTermMemory;

		achievedNewProgress = currentTask.achievedNewProgress;
		tasksLeft = currentTask.tasksLeft; //not yet askede

		comingUp = currentTask.tasksLeft + currentTask.available_questions;
	}

	onMount(() => {
		window.addEventListener("keyup", globalKeyupHandler);
	});

	onDestroy(() => {
		window.removeEventListener("keyup", globalKeyupHandler);
	});

	function globalKeyupHandler(event: KeyboardEvent) {
		if (event.key === "Enter") {
			onEnter();
		}
	}

	async function ItsCorrect(id: number) {
		var clientUrl = "/Crammer/Correct/" + id;

		currentTask = await fetchGet<Translation>(clientUrl);
		showCurrentTask();
	}

	let progressBarWidth = "0%"; // Start with the progress bar empty

	async function ItsWrong(id: number) {
		// Initiate progress bar animation by ensuring it starts at 0%
		showQuestionNotSolution = false;
		progressBarWidth = "0%";
		await tick(); // Let Svelte update the DOM

		// Trigger the animation to 100%
		progressBarWidth = "100%";

		// Reset after animation (e.g., 3 seconds animation duration)
		setTimeout(() => {
			showQuestionNotSolution = true;
			progressBarWidth = "0%";
			focusInput();
		}, 3000); // Adjust to match the CSS animation duration

		var clientUrl = "/Crammer/Wrong/" + id;
		let ok = await fetchGet<string>(clientUrl);
	}

	async function setCurrentLesson(slug: string): Promise<void> {
		var clientUrl = "/Crammer/SetCurrentLesson/" + slug;
		await fetchGet<void>(clientUrl);

		//get title and description

		clientUrl = "/Crammer/GetCurrentLesson/" + slug;
		let currentLesson = await fetchGet<LessonVM>(clientUrl);

		lessonDescription = currentLesson.description;
		title = currentLesson.title;
	}

	function focusInput() {
		if (inputElement) {
			inputElement.focus();
			//console.log("Focus attempted on inputElement.");
		} else {
			//console.log("inputElement is not available.");
		}
	}
	function restart(): any {
		location.reload();
	}

	function showHints(): any {
		showInstructions = true;
	}

	async function forgetLesson(event: MouseEvent & { currentTarget: EventTarget & HTMLButtonElement }) {
		var clientUrl = "/Crammer/ForgetLesson/";
		await fetchGet<void>(clientUrl);
		location.reload();
	}
</script>

<!-- <div>
	manually inserted DebugConst
	<DebugConst></DebugConst>
	end of manually inserted DebugConst
</div> -->

<div class="score-button">
	<button on:click={() => navigate("/home")} class="bttn">
		<img
			alt="home"
			src="/homeicon.svg"
			style="display: inline-block; vertical-align: middle; background-color:white; margin-bottom:2px;"
			width="20px"
			height="20px"
		/>Home
	</button>
	<button on:click={() => restart()} class="bttn">
		<img
			alt="restart"
			src="/restart.svg"
			style="display: inline-block; vertical-align: middle;"
			width="20px"
			height="20px"
		/>Restart</button
	>
	<button on:click={forgetLesson} class="forget-lesson bttn">Forget Lesson</button>
</div>

<NewGame></NewGame>

{#if finished}
	<div style="color:red; font-size: 12px;">No more questions for now. Need to wait to transfer into long term memory</div>
	<br />
{/if}

<!-- <h2>Coming up: {comingUp}</h2> -->
<div class="stair-keypad">
	<div class="task-left">
		<!-- <div class="task-left">
		{#if tasksLeft}
			<h>Tasks left in challenge: {tasksLeft}</h>
		{/if}
	 </div> -->
		<div class="to-do">
			<div class="task">
				<!-- <div>
					<div
						style="width: 60px; margin-left: 140px"
						class="box"
						data-number={remembered1week}
					></div>
					<div
						style="width: 80px; margin-left: 120px"
						class="box"
						data-number={remembered1day}
					></div>
					<div
						style="width: 100px; margin-left: 100px"
						class="box"
						data-number={remembered1hour}
					></div>
					<div
						style="width: 120px; margin-left: 80px"
						class="box"
						data-number={remembered30Minute}
					></div>

					<div
						style="width: 140px;  margin-left: 60px"
						class="box"
						data-number={remembered10Minute}
					></div>
					<div
						style="width: 160px; margin-left: 40px"
						class="box"
						data-number={remembered5Minute}
					></div>

					<div
						style="width: 180px; "
						class="box"
						data-number={remembered2Minute}
					></div>
					<div
						style="width: 200px;"
						class="box"
						data-number={remembered1Minute}
					></div>
				</div> -->
				<div class="words">
					<img alt="icon" src="/stairs.svg" class="stairs" style="" />
					<div class="time1 onemonth date">
						{#if remembered1month}
							<span>{remembered1month}</span>
						{/if}
					</div>
					<div class="time1 oneweek date">
						{#if remembered1week}
							<span>{remembered1week}</span>
							<span>{remembered1week}</span>
						{/if}
					</div>
					<div class="time1 oneday date">
						{#if remembered1day}
							<span>{remembered1day}</span>
							<span>{remembered1day}</span>
						{/if}
					</div>
					<div class="time1 onehour date">
						{#if remembered1hour}
							<span>{remembered1hour}</span>
						{/if}
					</div>
					<div class="time1 thirtymin date">
						{#if remembered30Minute}
							<span>{remembered30Minute}</span>
						{/if}
					</div>
					<div class="time1 tenmin date">
						{#if remembered10Minute}
							<span>{remembered10Minute}</span>
						{/if}
					</div>
					<div class="time1 fivemin date">
						{#if remembered5Minute}
							<span>{remembered5Minute}</span>
						{/if}
					</div>
					<div class="time1 twomin date">
						{#if remembered2Minute}
							<span>{remembered2Minute}</span>
						{/if}
					</div>
					<div class="time1 onemin date">
						{#if remembered1Minute}
							<span>{remembered1Minute}</span>
						{/if}
					</div>
					<div class="time1 one date"></div>
				</div>
			</div>
			<div class="repeat-available">
				<div class="repeat">
					<img alt="icon" src="/funnel.svg" class="funnel" style="" />
					{#if inLongTermMemory}
						<div style="margin-top:-33px" class="time longterm">
							<div>{inLongTermMemory}</div>
							<p>longTermMemory</p>
						</div>
					{/if}
					{#if repeatNext1M}
						<div style="margin-top:-25px" class="time one-month">
							<p>repeatNext1M</p>
							<div style="font-size: 10px;">{repeatNext1M}</div>
						</div>
					{/if}

					{#if repeatNext1W}
						<div style="margin-top:-23px" class="time one-week">
							<div style="font-size: 10px;">{repeatNext1W}</div>
							<p>repeatNext1W</p>
						</div>
					{/if}

					{#if repeatNext1D}
						<div style="margin-top:-18px" class="time one-day">
							<p>repeatNext1D</p>
							<div style="font-size: 10px;">{repeatNext1D}</div>
						</div>
					{/if}
					{#if repeatNext1H}
						<div style="margin-top:-11px" class="time one-hour">
							<div style="font-size: 10px;">{repeatNext1H}</div>
							<p>repeatNext1H</p>
						</div>
					{/if}
					{#if repeatNext30min}
						<div style="margin-top:-5px" class="time thirty-min">
							<p>repeatNext30min</p>
							<div style="font-size: 10px;">
								{repeatNext30min}
							</div>
						</div>
					{/if}
					{#if repeatNext10min}
						<div style="margin-top:1px" class="time ten-min">
							<div style="font-size: 10px;">
								{repeatNext10min}
							</div>
							<p>repeatNext10minutes</p>
						</div>
					{/if}
					{#if repeatNext5min}
						<div style="margin-top:8px" class="time five-min">
							<p>repeatNext5minutes</p>
							<div style="font-size: 10px;">{repeatNext5min}</div>
						</div>
					{/if}
					{#if repeatNext2min}
						<div style="margin-top:18px" class="time two-min">
							<div style="font-size: 10px;">{repeatNext2min}</div>
							<p>repeatNext2minutes</p>
						</div>
					{/if}
					<div style="margin-top:30px" class="time one-min">
						<p>repeatNext1minute</p>
						<div style="font-size: 10px;">{repeatNext1min}</div>
					</div>
				</div>
				<div class="available">
					{#if notRemembered}<div>
							Available: {notRemembered}
						</div>
					{/if}
				</div>
			</div>
		</div>
	</div>
	{#if isShowHighScore}
		<!-- <HiScore {slug}></HiScore> -->
	{/if}

	<!-- isMobile: {$isMobile} -->
	<div class="content">
		<div class="game">
			{#if isGameOver}
				<!-- <GameOver {results}></GameOver> -->
			{:else}
				<div class="keypad">
					<div>
						<div class="level">
							<h1 class="custom-h1">
								<p class="title">{lessonDescription}</p>
							</h1>
						</div>
						<div class="keypad-content">
							<div class="flex-box2">
								<div class="task-timer">
									<div>Coming up: {comingUp}</div>
								</div>
							</div>
							<div class="container">
								<div>
									<div class="background">
										{#if started}
											<div>
												<div class="flex-box">
													<div>{@html $mathQuestion}</div>
												</div>
												<div class="info-text">
													{infoText}
												</div>
											</div>
										{:else}
											<div class="flex-box">Press "Enter" to start</div>
											<div class="info-text">
												{infoText}
											</div>
										{/if}
									</div>
								</div>

								<div class="input-container">
									{#if started}
										{#if showQuestionNotSolution}
											<input
												type="text"
												class="my-input"
												bind:value={$userinput}
												bind:this={inputElement}
												readonly={$isMobile}
											/>
										{:else}
											<div class="correct-hint" id="correctHint">
												<div class="solution">
													{currentTask.answer}
												</div>
												<div class="progress-container">
													<div class="progress-bar" style="width: {progressBarWidth};"></div>
												</div>
											</div>
										{/if}
									{/if}
								</div>
								{#if showBackground}
									<div class="background1">
										<div>
											<div class="flex-box">
												<div>{@html $mathView}</div>
											</div>
											<div class="info-text">
												{infoText}
											</div>
										</div>
									</div>
								{/if}
							</div>

							<div class="flex-box1">
								<div class="grid-container">
									<button on:click={onSin} class="grid-item-sin custom-button">sin</button>
									<button on:click={onCos} class="grid-item-cos custom-button">cos</button>
									<button on:click={onE} class="grid-item-e custom-button">e</button>
									<button on:click={onLn} class="grid-item-ln custom-button">ln</button>
									<button on:click={onA} class="grid-item-a custom-button">a</button>
									<button on:click={onopenParenthesis} class="grid-item-open-parenthesis custom-button">(</button>
									<button on:click={oncloseParenthesis} class="grid-item-close-parenthesis custom-button">)</button>
									<button on:click={onXy} class="grid-item-xy custom-button">x<sup>y</sup></button>
									<button on:click={onX} class="grid-item-x custom-button">x</button>
									<button on:click={on7button} class="grid-item7 custom-button">7</button>
									<button on:click={on8button} class="grid-item8 custom-button">8</button>
									<button on:click={on9button} class="grid-item9 custom-button">9</button>
									<button on:click={on4button} class="grid-item4 custom-button">4</button>
									<button on:click={on5button} class="grid-item5 custom-button">5</button>
									<button on:click={on6button} class="grid-item6 custom-button">6</button>
									<button on:click={on1button} class="grid-item1 custom-button">1</button>
									<button on:click={on2button} class="grid-item2 custom-button">2</button>
									<button on:click={on3button} class="grid-item3 custom-button">3</button>
									<button on:click={onSqrt} class="grid-item-sqrt custom-button">√</button>
									<button on:click={on0button} class="grid-item-zero custom-button">0</button>
									<button on:click={onCommabutton} class="grid-item-comma custom-button">,</button>
									<button on:click={onEnter} class="grid-item-enter custom-button">Enter</button>
									<button on:click={onNumber} class="grid-item-n custom-button">n</button>
									<button on:click={onClear} class="grid-item-clear custom-button"
										>C <span class="tooltip">Clear</span></button
									>
									<button on:click={onDivide} class="grid-item-division custom-button">÷</button>
									<button on:click={onMultiply} class="grid-item-multiply custom-button">×</button>
									<button on:click={onSubtract} class="grid-item-subtract custom-button">-</button>
									<button on:click={onAdd} class="grid-item-addition custom-button">+</button>
								</div>
							</div>
							{#if InstructionComponent && showInstructions && started}
								<svelte:component this={InstructionComponent} A={paramA} B={paramB} QuestionStr={questionStr} />
							{:else if started && hintExists}
								<button on:click={() => showHints()} class="hint">Show hints!</button>
							{/if}
						</div>
					</div>
				</div>
			{/if}
		</div>
	</div>
</div>

<style>
	.keypad-content {
		display: flex;
		flex-direction: column;
		position: absolute;
		left: 0;
		margin: 0;
		padding: 0;
		width: auto;
	}
	.custom-button {
		background: #ffffff7c;
		border: 2px solid white;
		color: #fff;
		cursor: pointer;
		transition: box-shadow 0.2s ease;
	}

	.custom-button:hover {
		animation: glow-border 0.5s infinite alternate;
	}
	@keyframes glow-border {
		0% {
			border-color: white;
			box-shadow: 0 0 10px white;
		}
		100% {
			border-color: white;
			box-shadow: 0 0 20px white;
		}
	}
	.bttn {
		width: 100px;
		height: 30px;
		border: 2px solid rgb(255, 255, 0);
		background: none;
		color: white;
		border-radius: 5px;
		/*animation: pulse;*/
	}
	.bttn:hover {
		color: black;
		background: linear-gradient(to bottom, rgba(255, 183, 0, 0.269), yellow);
		/*transition: all 0.3s ease-in-out;*/
	}
	.forget-lesson {
		font-size: 12px;
	}
	.score-button {
		display: flex;
		flex-direction: row;
		margin-top: -55px;
		margin-left: 50px;
	}
	.keypad {
		display: flex;
		top: 5%;
		left: 0;
		right: 0;
		bottom: 0;
	}
	.content {
		display: flex;
		flex-direction: row;
		background-color: #000;
		margin-top: 50px;
	}

	.level {
		color: white;
		text-align: center;
		font-size: x-large;
		font-weight: bold;
	}
	.background {
		display: flex;
		width: 510px;
		height: 150px;
		justify-content: center;
		align-items: center;
		background-color: rgba(255, 255, 255, 0.274);
		border: 1px solid white;
		margin-bottom: 10px;
	}
	.background1 {
		display: flex;
		width: 510px;
		height: 150px;
		justify-content: center;
		align-items: center;
		background-color: rgba(255, 255, 255, 0.274);
		border: 1px solid white;
		margin-top: 20px;
	}
	.flex-box {
		color: #fff;
		font-size: 1.8em;
		font-weight: bolder;
		text-align: center;
	}
	.container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.input-container {
		display: flex;
		justify-content: center;
		align-items: center;
		padding-top: 10px;
		height: 50px;
	}

	.my-input {
		width: 500px;
		height: 50px;
		background-color: #fff;
		border: 3px solid gray;
		color: #000;
		font-size: 30px;
		margin-top: 5px;
		margin-bottom: 10px;
		text-align: center;
		line-height: 50px;
	}

	.my-input:focus {
		outline: none;
		border-color: blue;
	}

	.flex-box1 {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.flex-box2 {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.task-timer {
		display: flex;
		position: center;
		font-size: 25px;
		width: 500px;
		height: 50px;
		padding: 10px;
		justify-content: space-between;
		align-items: center;
		color: #fff;
		background-color: rgba(0, 0, 0, 0.4);
	}

	button {
		width: 75px;
		height: 75px;
		border-radius: 10px;
	}

	.grid-container {
		display: grid;
		grid-template-columns: repeat(6, 1fr);
		grid-template-rows: repeat(5, 1fr);
		font-size: 40px;
		justify-content: center;
		align-items: center;
	}
	.grid-item-sin {
		grid-column: 1/2;
		grid-row: 1/2;
	}
	.grid-item-cos {
		grid-column: 2/2;
		grid-row: 1/2;
	}
	.grid-item-e {
		grid-column: 3/3;
		grid-row: 1/2;
	}
	.grid-item-ln {
		grid-column: 4/4;
		grid-row: 1/2;
	}
	.grid-item-a {
		grid-column: 5/5;
		grid-row: 1/2;
	}
	.grid-item-open-parenthesis {
		grid-column: 1/2;
		grid-row: 2/2;
	}
	.grid-item-close-parenthesis {
		grid-column: 1/2;
		grid-row: 3/3;
	}
	.grid-item7 {
		grid-column: 2/3;
		grid-row: 2/2;
		background: rgba(255, 255, 255, 0.274);
	}

	.grid-item8 {
		grid-column: 3/4;
		grid-row: 2/2;
		background: rgba(255, 255, 255, 0.274);
	}

	.grid-item9 {
		grid-column: 4/5;
		grid-row: 2/2;
		background: rgba(255, 255, 255, 0.274);
	}

	.grid-item4 {
		grid-column: 2/2;
		grid-row: 3/3;
		background: rgba(255, 255, 255, 0.274);
	}

	.grid-item5 {
		grid-column: 3/3;
		grid-row: 3/3;
		background: rgba(255, 255, 255, 0.274);
	}

	.grid-item6 {
		grid-column: 4/4;
		grid-row: 3/3;
		background: rgba(255, 255, 255, 0.274);
	}

	.grid-item1 {
		grid-column: 2/2;
		grid-row: 4/4;
		background: rgba(255, 255, 255, 0.274);
	}

	.grid-item2 {
		grid-column: 3/3;
		grid-row: 4/4;
		background: rgba(255, 255, 255, 0.274);
	}

	.grid-item3 {
		grid-column: 4/4;
		grid-row: 4/4;
		background: rgba(255, 255, 255, 0.274);
	}
	.grid-item-zero {
		grid-column: 3/3;
		grid-row: 5/5;
		background: rgb(69, 69, 69);
	}
	.grid-item-x {
		grid-column: 1/1;
		grid-row: 5/5;
	}
	.grid-item-comma {
		grid-column: 4/4;
		grid-row: 5/5;
	}
	.grid-item-xy {
		grid-column: 1/2;
		grid-row: 4/4;
	}
	.grid-item-sqrt {
		grid-column: 2/2;
		grid-row: 5/5;
	}
	.grid-item-division {
		grid-column: 5/6;
		grid-row: 2/2;
	}
	.grid-item-multiply {
		grid-column: 5/6;
		grid-row: 3/3;
	}
	.grid-item-subtract {
		grid-column: 5/6;
		grid-row: 4/4;
	}
	.grid-item-addition {
		grid-column: 5/6;
		grid-row: 5/5;
	}
	.grid-item-n {
		grid-column: 6/6;
		grid-row: 3/3;
	}
	.grid-item-clear {
		grid-column: 6/6;
		grid-row: 2/2;
		background: rgba(104, 110, 136, 0.79);
		position: relative;
		padding: 10px;
		cursor: pointer;
	}
	.grid-item-clear .tooltip {
		visibility: hidden;
		width: 60px;
		background-color: none;
		color: #fff;
		text-align: center;
		font-size: 18px;
		padding: 5px;
		position: absolute;
		bottom: 110%;
		left: 50%;
		transform: translateX(-50%);
		z-index: 1;
		transition:
			visibility 0s linear 0.5s,
			opacity 0.5s ease;
	}
	.grid-item-clear .tooltip::after {
		position: absolute;
		top: 100%;
		left: 50%;
		margin-left: -5px;
		border-width: 5px;
	}
	.grid-item-clear:hover .tooltip {
		visibility: visible;
		opacity: 1;
		transition-delay: 0.9s;
	}
	.grid-item-enter {
		grid-column: 6/7;
		grid-row: 4/6;
		height: 160px;
		font-size: 20px;
		background: rgba(104, 110, 136, 0.79);
	}
	.title {
		font-size: 0.4em;
		width: 400px;
		margin-top: 40px;
		padding: 0;
	}
	.custom-h1 {
		padding: 0;
	}
	.info-text {
		color: white;
	}
	.correct-hint {
		display: flex;
		flex-direction: row;
		position: relative;
		width: 300px;
		height: 50px;
		background-color: red;
		border: 1px solid white;
		color: white;
		justify-content: center;
		align-items: center;
		animation: glow 1s infinite alternate;
	}
	@keyframes glow {
		0% {
			background-color: lightgreen;
			box-shadow: 0 0 5px 3px lightgreen;
		}
		100% {
			background-color: red;
			box-shadow: 0 0 10px 5px red;
		}
	}
	.progress-container {
		display: flex;
		position: absolute;
		bottom: 0;
		left: -10;
		width: 300px;
		height: 5px;
		margin-top: 5px;
		border-radius: 0 5px 5px 0;
		overflow: hidden;
	}
	.progress-bar {
		width: 0%; /* Start width, maybe not needed*/
		height: 5px;
		background-color: white;
		border-radius: 0 5px 5px 0;
		transition: width 3s ease;
	}

	.solution {
		font-size: 20px;
	}
	.hint {
		background: none;
		border: 2.5px solid yellow;
		color: #fff;
		width: 70px;
		height: 60px;
		font-size: 15px;
		margin-left: 10px;
	}
	.to-do {
		display: flex;
		flex-direction: row;
		font-size: 10px;
		color: white;
		height: 80px;
		padding: 10px;
		left: 10px;
		z-index: 1;
	}

	.task {
		display: flex;
		flex-direction: row;
	}
	.task-left {
		display: flex;
		flex-direction: row;
	}
	.stair-keypad {
		display: flex;
		flex-direction: column;
	}
	.repeat-available {
		display: flex;
		flex-direction: column;
		z-index: 1;
	}
	.available {
		margin-top: -20px;
		color: gold;
	}
</style>
