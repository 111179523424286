// Profile.ts
import { fetchGet } from './fetch';
import { rating } from './stores';

interface TaskInterfaceVm {
    Url: string;
    Title: string;
    Value: number;
}

export interface TaskVm {
    MemoryTasks: TaskInterfaceVm[];
    IntroTasks: TaskInterfaceVm[];
    MentalTasks: TaskInterfaceVm[];
    AppliedTasks: TaskInterfaceVm[];
    rating: number; //maybe this should have been in the progressVm
}


export async function getHomeTasksVm(): Promise<TaskVm> {
    const tasks = await fetchGet<TaskVm>("FastMentalMath/GetHomeTasks");
    console.log(tasks);

    return tasks;
}

interface ProgressVm {
    introProgress: number;
    memoryProgress: number;
    mentalMathProgress: number;
    appliedMathProgress: number;
   // problemsSolved: number;
}

export async function getProgressVm() {
    let progress = await fetchGet<ProgressVm>("Progress/GetProgress");

    return progress;
}

export async function updateRating() {
    let tasks = await getHomeTasksVm();
    rating.set(tasks.rating);
}

