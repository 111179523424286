// s0rc/authStore.ts
import { writable } from 'svelte/store';

export const isAuthenticated = writable(false);
export const connectionTrouble = writable(false);
export const isMobile = writable(false);
export const isLoading = writable(false);
export const rating = writable(0);
export const userName = writable("");
export const newEvent = writable("");
export const userinput = writable('');
export const calcEnter = writable(false);
export const mathContent = writable("");
export const volume = writable(1.0);


